<template>
  <div class="matrix-select mt-6">
    <div
      v-if="addTextFilter && !readOnly"
      flat
      class="ml-3 mt-2"
    >
      <v-switch
        v-model="useTextFilter"
        :label="`Use filter on ${itemName.multi} that contain text`"
      />
      <transition name="fade">
        <div v-show="useTextFilter">
          <v-radio-group v-model="typeOfTextFilter">
            <v-radio
              :label="`include ${itemName.multi}`"
              value="include"
            />
            <v-radio
              :label="`exclude ${itemName.multi}`"
              value="exclude"
            />
          </v-radio-group>
          <v-text-field
            v-model="textFilter"
            class="mt-3"
            :label="
              `${typeOfTextFilter} ${itemName.multi} that contain this text`
            "
            dense
            outlined
          />
        </div>
      </transition>
    </div>

    <v-data-table
      :headers="headers"
      :items="localItems"
      :hide-default-footer="showDefaultFooter()"
      :hide-default-header="hideDefaultHeader"
      disable-sort
      :options="{
        itemsPerPage: itemsPerPage
      }"
      :footer-props="{
        itemsPerPageText: `number of ${itemName.multi} per page`
      }"
      style="background: none"
    >
      <!-- eslint-disable-next-line -->
      <template #item.name="{ item }">
        <span v-text="item.name" />
      </template>

      <template
        v-for="selectable in localSelectables"
        #[`item.${selectable.columnName}`]="{ item }"
      >
        <v-checkbox
          :key="`${item[selectable.columnName]}${item.id}`"
          v-model="item[selectable.columnName]"
          :readonly="readOnly"
          :ripple="false"
          @change="handleChange"
        />
      </template>
      <!-- eslint-disable-next-line -->
      <template #header.name="{}">
        <div
          v-if="namingConventions && !readOnly"
          class="mt-4"
        >
          <v-btn
            outlined
            x-small
            @click="selectNone"
          >
            none
          </v-btn>
          <v-btn
            class="ml-2"
            outlined
            x-small
            @click="selectFromNamingConventions"
          >
            use naming conventions
          </v-btn>
        </div>
      </template>

      <template
        v-for="selectable in localSelectables"
        #[`header.${selectable.columnName}`]="{ header }"
      >
        {{ header.text }}
        <v-checkbox
          v-show="!readOnly"
          :key="`${header[selectable.columnName]}`"
          v-model="multiSelectedColumns"
          :readonly="readOnly"
          :value="selectable.columnName"
          :ripple="false"
          @click.stop="
            selectAllFromColumn($event, selectable.columnName, value)
          "
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import * as Initialisation from './initialize'
import * as TextFilter from './text-filter'
import * as InputHandling from './input-handling'
// import { initialize, initializeValueForExport, populateValueForExport } from "./initialize";
export default {
  props: {
    itemName: {
      type: Object,
      default: () => {
        return  {
          single: 'item',
          multi: 'items',
        }
      },
    },
    formName: {
      type: String,
      required: true,
    },
    hideDefaultFooter: {
      type: Boolean,
      default: false,
    },
    hideDefaultHeader: {
      type: Boolean,
      default: false,
    },
    itemsPerPage: {
      type: Number,
      default: 5,
    },
    name: {
      type: String,
      default: 'someMatrixEditor',
    },
    formItemValue: {
      type: Object,
      required: true,
    },
    tempData: {
      type: String,
      required: true,
    },
    metaData: {
      type: Object,
      required: true,
    },
    selectables: {
      type: Array,
      required: true,
    },
    namingConventions: {
      type: Boolean,
      value: false,
    },
    addTextFilter: {
      type: Boolean,
      value: false,
    },
    items: {
      type: Array,
      required: true,
    },
    fetchItems: {
      type: Object,
      required: false,
      default: () => {},
    },
    label: {
      type: String,
      required: true,
    },
    singleSelect: {
      type: Boolean,
      default: false,
    },
    useCustomMatrixHeaders: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      initialHeader: { text: 'Name', value: 'name' },
      useTextFilter: false,
      typeOfTextFilter: 'include',
      textFilter: '',
      headers: [],
      multiSelectedColumns: [],
      localSelectables: [],
      localItems: [],
      tempItems: [],
      value: {},
    }
  },
  computed: {
    formVariables() {
      if (
        Object.hasOwnProperty.call(
          this.$store.state.AsfForms.forms,
          this.formName,
        )
      ) {
        return this.$store.state.AsfForms.forms[this.formName].formVariables
      }
      return {}
    },
    settingsVariables() {
      return this.$store.state.AsfForms.settingsVariables
    },
  },
  watch: {
    formVariables: {
      handler: function() {
        if (
          Object.hasOwnProperty.call(this, 'fetchItems') &&
          this.fetchItems !== null &&
          this.fetchItems.dependencies
        ) {
          this.initialize()
          this.handleChange()
        }
      },
      deep: true,
    },
    settingsVariables: {
      handler: function() {
        this.initialize()
        this.handleChange()
      },
      deep: true,
    },
    customMatrixHeaders() {
      setTimeout(() => {
        this.initialize()
        this.handleChange()
      }, 1000)
    },
    useTextFilter() {
      this.switchTextFilter()
    },
    typeOfTextFilter() {
      this.switchTextFilter()
    },
    textFilter() {
      this.switchTextFilter()
    },
  },
  beforeMount() {
    this.initialize()
    this.handleChange()
  },
  methods: {
    ...Initialisation,
    ...TextFilter,
    ...InputHandling,
    showDefaultFooter() {
      if (this.hideDefaultFooter) {
        return true
      } else {
        return this.localItems.length < 5
      }
    },
  },
}
</script>

<style lang="scss">
@import "matrix-select.module";
</style>
