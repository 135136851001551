<template>
  <v-stepper
    v-model="currentStep"
    vertical
  >
    <v-stepper-step
      v-for="panel in panels"
      v-show="panel.show.value === true"
      :key="panel.id"
      :complete="currentStep > panel.id"
      :step="panel.id"
    >
      <h3 class="font-weight-light">
        {{ panel.title }}
      </h3>
      <div
        v-if="$store.state.AsfForms.settingsVariables.showIds"
        class="ml-2 text-caption"
      >
        panel.id = {{ panel.id }}
      </div>
      <div style="position: absolute; right: 52px">
        <asf-debug-modal
          v-if="showDebugger(panel)"
          :show-data="panel"
          :form-name="formName"
          :panel-id="panel.id"
        />
      </div>
      <v-stepper-content
        :style="`width: ${stepperWidth}px`"
        :step="panel.id"
      >
        <v-card
          class="mt-4"
          :style="setStyleGridParent(panel)"
        >
          <asf-form-item-builder
            v-for="formItem in panel.formItems"
            v-show="formItem.show.value === true"
            :key="formItem.id"
            class="mt-5"
            :style="setStylelGridItem(formItem)"
            :form-item="formItem"
            :form-name="formName"
            :panel-id="panel.id"
            @itemUpdated="updateFormItem"
            @tempDataUpdated="updateFormItemTempData"
            @metaDataUpdated="updateFormItemMetaData"
          />
        </v-card>
        <v-btn
          color="primary"
          @click="nextStep"
        >
          Continue
        </v-btn>
        <v-btn
          text
          class="ml-2"
          @click="previousStep"
        >
          Cancel
        </v-btn>
      </v-stepper-content>
    </v-stepper-step>
  </v-stepper>
</template>

<script>
export default {
  props: {
    formName: {
      type: String,
      required: true,
    },
    formConfig: {
      type: Object,
      required: true,
    },
    customOpenPanels: {
      type: Array,
      default: () => [],
    },
    allPanelsOpen: {
      type: Boolean,
      default: false,
    },
    panels: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      openPanels: this.customOpenPanels,
      currentStep: 1,
      stepperWidth: 900,
    }
  },
  computed: {
    settingsVariables() {
      return this.$store.state.AsfForms.settingsVariables
    },
  },
  beforeMount() {
    if (this.allPanelsOpen === true && this.customOpenPanels.length === 0) {
      this.openAllPanels()
    }
  },
  methods: {
    updateFormItem(value) {
      value.formName = this.formName
      this.$store.dispatch('AsfForms/setDataFormItem', value)
    },
    updateFormItemTempData(value) {
      value.formName = this.formName
      this.$store.dispatch('AsfForms/setTempDataFormItem', value)
    },
    updateFormItemMetaData(value) {
      value.formName = this.formName
      this.$store.dispatch('AsfForms/setMetaDataFormItem', value)
    },
    openAllPanels() {
      let iterator = 0
      this.openPanels = []
      this.panels.forEach(() => {
        this.openPanels.push(iterator)
        iterator++
      })
    },
    previousStep() {
      const numberOfSteps = this.panels.length
      this.currentStep =
        this.currentStep !== 1 ? this.currentStep - 1 : numberOfSteps
    },
    nextStep() {
      const numberOfSteps = this.panels.length
      this.currentStep =
        this.currentStep !== numberOfSteps ? this.currentStep + 1 : 1
    },
    showDebugger(panel) {
      if (Object.hasOwnProperty.call(panel, 'debug') && panel.debug) {
        return true
      }

      if (Object.hasOwnProperty.call(this.settingsVariables, 'showPanelData')) {
        return this.settingsVariables.showPanelData
      }
      return false
    },
    setStyleGridParent(parentItem) {
      if (
        !Object.hasOwnProperty.call(parentItem, 'layout') ||
        !Object.hasOwnProperty.call(parentItem.layout, 'useGrid') ||
        parentItem.layout.useGrid === false
      ) {
        return null
      }

      const gridDefinition = this.initializeGridDefinition(parentItem)
      return this.setGridStyle(gridDefinition)
    },
    setStylelGridItem(gridItem) {
      if (
        !Object.hasOwnProperty.call(gridItem, 'layout') ||
        !Object.hasOwnProperty.call(gridItem.layout, 'gridArea')
      ) {
        return null
      }
      return `grid-area: ${gridItem.layout.gridArea};`
    },
    initializeGridDefinition(parentItem) {
      const defaultGridDefinition = {
        gridTemplateColumns: 'repeat(4, 1fr)',
        gridTemplateRows: '1fr',
        gridColumnGap: '6px',
        gridRowGap: '5px',
      }

      if (!Object.hasOwnProperty.call(parentItem.layout, 'gridDefinition')) {
        return this.setGridStyle(defaultGridDefinition)
      }

      const newDef = defaultGridDefinition
      const panDef = parentItem.layout.gridDefinition

      if (Object.hasOwnProperty.call(panDef, 'gridTemplateColumns')) {
        newDef.gridTemplateColumns = panDef.gridTemplateColumns
      }

      if (Object.hasOwnProperty.call(panDef, 'gridTemplateRows')) {
        newDef.gridTemplateRows = panDef.gridTemplateRows
      }

      if (Object.hasOwnProperty.call(panDef, 'gridColumnGap')) {
        newDef.gridColumnGap = panDef.gridColumnGap
      }

      if (Object.hasOwnProperty.call(panDef, 'gridRowGap')) {
        newDef.gridRowGap = panDef.gridRowGap
      }

      return newDef
    },
    setGridStyle(definition) {
      const style =
        'display: grid;' +
        `grid-template-columns: ${definition.gridTemplateColumns};` +
        `grid-template-rows: ${definition.gridTemplateRows};` +
        `grid-column-gap: ${definition.gridColumnGap};` +
        `grid-row-gap: ${definition.gridRowGap};`
      return style
    },
  },
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
 {
  opacity: 0;
}

.v-expansion-panel--active:not(:first-child),
.v-expansion-panel--active + .v-expansion-panel {
  margin-top: 0;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
</style>
