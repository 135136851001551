<template>
  <v-container class="crf crf__main">
    <div class="crf__logo">
      <slot name="logo" />
    </div>
    <v-container>
      <h2
        v-if="title"
        class="crf__title"
      >
        {{ title }}
      </h2>
      <v-container
        v-if="beamTitle"
        class="crf__header primary"
        fluid
      >
        <h3 class="crf__region">
          {{ beamTitle }}
        </h3>
      </v-container>
      <slot name="titleArea" />
      <v-container class="crf__form-container">
        <asf-form-builder
          :ref="formName"
          :form-config="formConfig"
          @updateFormValidity="updateFormValidity"
        >
          <template #submitArea>
            <v-container style="min-height: 120px">
              <v-btn
                v-if="hasSubmitButton"
                :id="`${formName}_validate_submit_button`"
                :color="validAndAgreed ? 'primary' : 'greyed'"
                :disabled="isLoading"
                class="ma-1 primary"
                @click="submitAction"
                v-text="
                  validAndAgreed ? submitButtonTitle : validateButtonTitle
                "
              />
              <v-alert
                v-if="$store.state.Project.errorMessage"
                :id="`${formName}_error_field`"
                class="ma-4 warning"
                type="error"
              >
                {{ $store.state.Project.errorMessage }}
              </v-alert>
              <v-alert
                v-if="localErrorMessage !== null"
                :id="`${formName}_local_error_field`"
                class="ma-4 warning"
                type="error"
              >
                {{ localErrorMessage }}
              </v-alert>
            </v-container>
          </template>
        </asf-form-builder>
      </v-container>
    </v-container>
    <!--    <v-btn @click="showDialog = true">Show Dialog</v-btn>-->
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="800"
      width="480"
    >
      <v-card>
        <v-card-title class="mb-6">
          <slot name="dialogLogo" />
        </v-card-title>
        <v-card-text id="confirmation-dialog">
          {{
            $store.state.Project.successMessage
          }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            id="confirmation-button"
            color="primary darken-1"
            text
            @click="handleShowDialogClick"
          >
            Dat is duidelijk.
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { nestedProperty } from '../asf/stateful-forms'

export default {
  props: {
    formName: {
      type: String,
      default: 'formConfigOpCharge',
    },
    documentTitle: {
      type: String,
      default: 'Charger request form',
    },
    faviconFileName: {
      type: String,
      default: 'evtools-favicon.ico',
    },
    title: {
      type: String,
      default: undefined,
    },
    beamTitle: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      localErrorMessage: null,
      valid: false,
    }
  },
  computed: {
    formConfig() {
      return this.$store.state.AsfForms.forms[this.formName]
    },
    formRef() {
      const formParent = this.$refs[this.formName]
      return formParent.$refs.formBuilder.$refs.form
    },
    showDialog: {
      get() {
        return this.$store.state.Project.formHasBeenSentSuccessfully
      },
      set(value) {
        this.$store.dispatch('Project/setFormHasBeenSentSuccessfully', value)
      },
    },
    hasSubmitButton() {
      return Object.hasOwnProperty.call(this.formConfig, 'submitButton')
    },
    submitButtonTitle() {
      return (
          nestedProperty(this.formConfig, 'submitButton', 'title') || 'submit'
      )
    },
    validateButtonTitle() {
      return (
          nestedProperty(this.formConfig, 'submitButton', 'validateTitle') ||
          'validate'
      )
    },
    agreed() {
      // keeps the checkbox for terms to agree to in sync without showing error to begin
      // this because we needed to have required fields, also if they are not in view
      // todo:: extend to make multiple possible?

      // If the formConfig contains formConfig.submitButton.useSettingsKeyAgreed
      // The parser looks for a formItem that sets a settingsKey with the name 'agreed':
      // formItem.options.settingsKey = "agreed"

      const useSettingsKeyAgreed =
          nestedProperty(
              this.formConfig,
              'submitButton',
              'useSettingsKeyAgreed',
          ) || false

      let agreed =
          this.$store.getters['AsfForms/listenToSettingsKey']('agreed')
      if (useSettingsKeyAgreed) {
        // This settingsKey will only be available once a value has been inputted to it
        // before that it's non existing in the store.
        // therefore; the evaluation below will result false up until the point the checkbox
        // has been checked.

        agreed = typeof agreed === 'boolean' && agreed === true
      } else {
        agreed = true
      }
      return agreed
    },
    validAndAgreed() {
      return this.valid && this.agreed
    },
  },
  mounted() {
    document.title = this.documentTitle
    const linkElement = document.querySelector('link[rel=icon]')
    linkElement.href = `${window.location.href}img/tenant-icons/${this.faviconFileName}`
    document.head.appendChild(linkElement)
  },
  methods: {
    updateFormValidity(value) {
      this.valid = value
    },
    validateForm() {
      this.formRef.validate()
    },
    // resetValidation() {
    //   this.formRef.resetValidation();
    // },
    resetForm() {
      this.$store.dispatch('AsfForms/resetForm', this.formName).then(() => {
        this.formRef.reset()
      })
    },
    async submitAction() {
      this.validateForm()

      if (this.validAndAgreed === false) {
        // eslint-disable-next-line
        console.log('This form is not valid')
        return
      }

      this.isLoading = true

      if (
          Object.hasOwnProperty.call(this.formConfig.submitButton, 'action') &&
          Object.hasOwnProperty.call(
              this.formConfig.submitButton.action,
              'storeAction',
          )
      ) {
        if (
            Object.hasOwnProperty.call(
                this.formConfig.submitButton.action,
                'actionArgument',
            )
        ) {
          const argument = this.formConfig.submitButton.action.actionArgument
          await this.$store
              .dispatch(this.formConfig.submitButton.action.storeAction, argument)
              .catch((error) => {
                // eslint-disable-next-line
                console.log('error', error)
                this.triggerLocalError(
                    'There was an error, please try again later or ask your system admin.',
                )
              })
        } else {
          await this.$store
              .dispatch(this.formConfig.submitButton.action.storeAction)
              .catch((error) => {
                // eslint-disable-next-line
                console.log('error', error)
                this.triggerLocalError(
                    'There was an error, please try again later or ask your system admin.',
                )
              })
        }
      } else {
        alert('You clicked a button but nothing happened')
        this.isLoading = false
      }
    },
    triggerLocalError(message) {
      this.localErrorMessage = message
      setTimeout(() => {
        this.localErrorMessage = null
      }, 6000)
    },
    handleShowDialogClick() {
      this.resetForm()
      this.showDialog = false
      window.scrollTo(0, 0)
      this.isLoading = false
    },
  },
}
</script>

<style lang="scss">
@import "~vuetify/src/styles/settings/_variables";

.crf {
  &__main {
    max-width: 1280px;
  }

  &__title {
    padding: 30px 20px;
    color: #000000;
    font-size: 30px;
    font-weight: 300;
  }

  &__header {
    color: #fff;
    margin-bottom: 30px;
  }

  &__region {
    font-size: 30px;
    font-weight: 300;
  }

  &__form-container {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    &__logo {
      margin: 8px 0px 0px 6px;
    }

    &__header {
      padding: 12px 16px;
      color: #fff;
      margin-bottom: 0px;
    }

    &__title {
      font-size: 24px;
    }
    &__form-container {
      padding-left: 0px;
      padding-right: 0px;
    }
    &.container {
      padding: 0px !important;
    }
  }

  &.v-expansion-panel::before {
    box-shadow: none !important;
  }
}
</style>
