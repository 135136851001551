<template>
  <asf-multi-form-wrapper
    :default-form-id="defaultFormId"
    :forms="manualForms"
  />
</template>

<script>
export default {
  data() {
    return {
      defaultFormId: 1,
      manualForms: [
        {
          id: 1,
          title: 'Conditional show property',
          path: this.$store.state.AsfForms.forms.manualConditionalShowProperty,
        },
        {
          id: 2,
          title: 'Dynamically sharing data between components',
          path: this.$store.state.AsfForms.forms.manualSharingDataBetweenComponents,
        },
        {
          id: 3,
          title: 'String replacement',
          path: this.$store.state.AsfForms.forms.manualStringReplacement,
        },
        {
          id: 4,
          title: 'Validation',
          path: this.$store.state.AsfForms.forms.manualValidation,
        },
        {
          id: 5,
          title: 'Validation Multi Select',
          path: this.$store.state.AsfForms.forms.manualValidationMultiSelectObjects,
        },
        {
          id: 6,
          title: 'Settings',
          path: this.$store.state.AsfForms.forms.manualSettings,
        },
      ],
    }
  },
}
</script>
