<template>
  <v-select
    v-model="localValue"
    :items="formItem.items"
    :rules="rules"
    :menu-props="{ maxHeight: '400' }"
    :readonly="readOnly"
    :hint="formItem.options ? formItem.options.hint : null"
  >
    <template #label>
      <div style="margin-bottom: 20px">
        {{ processReplacements(formItem.title) }}
      </div>
    </template>
    <template #item="{ item }">
      <div
        :style="`width: 100%; padding: 8px; background-color: ${item}`"
        class="d-flex flex-row justify-center"
      >
        <div style="color: white">
          {{ item }}
        </div>
        <div style="width: 40px">
&nbsp;
        </div>
        <div style="color: black">
          {{ item }}
        </div>
      </div>
    </template>
    <template #selection="{ item }">
      <div
        :style="
          `margin-top: 10px; width: 100%; padding: 8px; background-color: ${item}`
        "
        class="d-flex flex-row justify-center"
      >
        <div style="color: white">
          {{ item }}
        </div>
        <div style="width: 40px">
&nbsp;
        </div>
        <div style="color: black">
          {{ item }}
        </div>
      </div>
    </template>
  </v-select>
</template>

<script>
import { mixinFormItemGeneric } from '../mixins/mixin-form-item-generic'

export default {
  mixins: [mixinFormItemGeneric],
}
</script>
