<template>
  <div class="selectWrapper">
    <v-select
      v-model="localValue"
      :items="formItem.items"
      :rules="rules"
      :menu-props="{ maxHeight: '400' }"
      :multiple="multiple"
      :chips="multiple"
      :deletable-chips="multiple"
      :label="title"
      :readonly="readOnly"
      :hint="hint"
    >
      <!--    <template v-slot:selection="{ item }">-->
      <!--      <div style="margin-top: 10px; width: 100%; padding: 8px;">-->
      <!--        <div style="color: black">{{ item }}</div>-->
      <!--      </div>-->
      <!--    </template>-->
    </v-select>
  </div>
</template>

<script>
import { mixinFormItemGeneric } from '../mixins/mixin-form-item-generic'

export default {
  mixins: [mixinFormItemGeneric],
}
</script>

<style lang="scss">
.selectWrapper {
  .v-text-field .v-label {
    top: 2px;
  }
}
</style>
