const customRulesForShowingFormItems = require('./custom-rules-for-showing-form-items')

class ConditionalPanels {
  constructor(panels) {
    this.panels = panels
    this.initializePanels()
    this.initializeFormItems()
    this.evaluatePanels()
    return this.panels
  }

  initializePanels() {
    this.panels.forEach(panel => {
      this.initializeShowProperty(panel)
    })
  }

  initializeFormItems() {
    this.panels.forEach(panel => {
      panel.formItems.forEach(formItem => {
        this.initializeShowProperty(formItem)
        this.initializeRadioItems(formItem)
      })
    })
  }

  initializeRadioItems(formItem) {
    if (
      formItem.type === 'radio' &&
      Object.hasOwnProperty.call(formItem, 'items')
    ) {
      formItem.items.forEach(radioItem => {
        this.initializeShowProperty(radioItem)
      })
    }
  }

  evaluatePanels() {
    this.panels.forEach(panel => {
      this.evaluateConditions(panel)
      this.evaluateFormItems(panel)
    })
  }

  evaluateFormItems(panel) {
    if (panel.show.value === true) {
      panel.formItems.forEach(formItem => {
        formItem.show.panelVisible = true

        this.evaluateConditions(formItem)
        this.evaluateRadioItems(formItem)
      })
    } else {
      panel.formItems.forEach(formItem => {
        formItem.show.panelVisible = false
      })
    }
  }

  evaluateRadioItems(formItem) {
    if (
      formItem.type === 'radio' &&
      Object.hasOwnProperty.call(formItem, 'items')
    ) {
      formItem.items.forEach(radioItem => {
        this.evaluateConditions(radioItem)
      })
    }
  }

  // If there is not a show property on the item;
  // the show options is set as a boolean
  // or the show value is set as a boolean true
  // showing the panel is assumed to be true

  initializeShowProperty(item) {
    if (
      !Object.prototype.hasOwnProperty.call(item, 'show') ||
      item.show === true
    ) {
      item.show = {
        value: true,
      }
    }
  }

  evaluateConditions(item) {
    // By default all conditions are true:
    let allConditionsTrue = true
    let oneOfTheConditionsTrue = false
    let evaluatedCondition = false
    // or evaluate the show.conditions attribute;
    if (
      typeof item.show === 'object' &&
      Object.prototype.hasOwnProperty.call(item.show, 'conditions')
    ) {
      item.show.conditions.forEach(condition => {
        if (
          Object.prototype.hasOwnProperty.call(condition, 'customCondition')
        ) {
          evaluatedCondition = this.evaluateCustomCondition(condition)
        } else {
          evaluatedCondition = this.evaluateCondition(condition)
        }

        if (evaluatedCondition) {
          oneOfTheConditionsTrue = true
        } else {
          allConditionsTrue = false
        }
      })

      item.show.value =
        this.evaluateMultipleConditionsMode(item) === 'allConditionsTrue'
          ? allConditionsTrue
          : oneOfTheConditionsTrue
    }
  }

  evaluateCondition(condition) {
    const pathArray = condition.formItemPath.split('.')
    const panelId = parseInt(pathArray[0])
    const formItemId = parseInt(pathArray[1])
    const operator = condition.operator
    const value = condition.value

    const panel = this.panels.find(panel => panel.id === panelId)

    // Is the panel visible?
    const lookupPanelVisible = panel.show.value === true

    const lookupFormItem = panel.formItems.find(
      formItem => formItem.id === formItemId,
    )
    const formItemPathValue = lookupFormItem.value
    const lookUpFormItemIsVisible = lookupFormItem.show.value

    let evaluateComparison = false

    // If the dependent formItem is not visible
    // don't evaluateComparison so it remains false

    if (lookupPanelVisible && lookUpFormItemIsVisible) {
      evaluateComparison = this.evaluateComparison(
        value,
        operator,
        formItemPathValue,
      )
    }

    return evaluateComparison
  }

  evaluateCustomCondition(condition) {
    if (Object.hasOwnProperty.call(condition, 'customCondition')) {
      return customRulesForShowingFormItems[condition.customCondition](
        this.panels,
        condition.data,
      )
    }
    return false
  }

  // multipleConditionsMode: evaluates as an OR condition when oneOfTheConditionsTrue
  // and evaluates as an AND condition allConditionsTrue
  evaluateMultipleConditionsMode(item) {
    return !Object.prototype.hasOwnProperty.call(
      item.show,
      'multipleConditionsMode',
    ) || item.show.multipleConditionsMode === 'oneOfTheConditionsTrue'
      ? 'oneOfTheConditionsTrue'
      : 'allConditionsTrue'
  }

  evaluateComparison(value, operator, formItemPathValue) {
    let comparisonResult

    switch (operator) {
      case '==':
        comparisonResult = formItemPathValue == value
        break
      case '===':
        comparisonResult = formItemPathValue === value
        break
      case '!=':
        comparisonResult = formItemPathValue != value
        break
      case '!==':
        comparisonResult = formItemPathValue !== value
        break
      case '>':
        comparisonResult = formItemPathValue > value
        break
      case '<':
        comparisonResult = formItemPathValue < value
        break
      case '>=':
        comparisonResult = formItemPathValue >= value
        break
      case '<=':
        comparisonResult = formItemPathValue <= value
        break
      case 'inArray':
        comparisonResult = value.includes(formItemPathValue)
        break
      default:
        comparisonResult = false
    }
    return comparisonResult
  }
}

module.exports = {
  ConditionalPanels,
}
