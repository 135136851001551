const dot = require('dot-object')

export class MapFormData {
  constructor(panels, dataRecord, locationShown = 0) {
    this.panels = panels
    this.dataRecord = dataRecord
    this.locationShown = locationShown
    this.flattenDataRecord()
    this.mapFormItems()
    return this.panels
  }

  flattenDataRecord() {
    const dataRecord = { ...this.dataRecord }
    const locations = dataRecord.Locations
    dataRecord.LocationDisplayed = locations[this.locationShown]

    // Before we flatten the record we prevent flattening the locations Array

    dataRecord.Locations = null

    // Arrays on a deeper level need to be kept into place as they will be used as an input value of a formItem
    // NOTE: if this is not the case this function is the place to implement the exception

    dot.keepArray = true
    this.dataRecordFlattened = dot.dot(dataRecord)

    // Now we add the locations array to the flattened array object

    this.dataRecordFlattened.Locations = locations
  }

  mapFormItems() {
    this.panels.forEach(panel => {
      panel.formItems.forEach(formItem => {
        this.mapFormItem(formItem)
      })
    })
  }

  mapFormItem(formItem) {
    const map = formItem.map
    formItem.value = this.dataRecordFlattened[map]
  }
}
