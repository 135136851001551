const {
  defaultErrorMessages,
} = require('../../data/misc/default-error-messages')
module.exports.packageValidationRuleDefinitions = (
  em = defaultErrorMessages,
) => [
  {
    validationKey: 'required',
    validationArgumentKey: 'required',
    ruleFunction: required => v => {
      if (required) {
        const isArray = Array.isArray(v)
        if (isArray) {
          return v.length > 0 || em.REQUIRED
        }
        return !!v || em.REQUIRED
      } else {
        return true
      }
    },
  },
  {
    validationKey: 'isInteger',
    ruleFunction: v => {
      const regex = /^-?[0-9]*$/
      return String(v).match(regex) ? true : em.INVALID_INTEGER
    },
  },
  {
    validationKey: 'isFloat',
    validationArgumentKey: 'precision',
    ruleFunction: precision => v => {
      const regex = regexForPrecision(precision)
      return String(v).match(regex) ? true : `${em.INVALID_FLOAT} ${precision}`
    },
  },
  {
    validationKey: 'minimum',
    validationArgumentKey: 'minimum',
    ruleFunction: minimumValue => v => {
      v = parseInt(v)
      const validation = v >= minimumValue
      return validation ? true : `${em.MINIMAL_VALUE} ${minimumValue}`
    },
  },
  {
    validationKey: 'maximum',
    validationArgumentKey: 'maximum',
    ruleFunction: maximumValue => v => {
      v = parseFloat(v)
      const validation = v <= maximumValue
      return validation
        ? true
        : `${em.MAXIMUM_VALUE}${maximumValue}`
    },
  },
]

const regexForPrecision = function(precision) {
  let regex

  switch (precision) {
    case 1:
      regex = /^-?\d+(\.\d{1,1})?$/
      break
    case 2:
      regex = /^-?\d+(\.\d{1,2})?$/
      break
    case 3:
      regex = /^-?\d+(\.\d{1,3})?$/
      break
    case 4:
    default:
      regex = /^-?\d+(\.\d{1,4})?$/
      break
    case 5:
      regex = /^-?\d+(\.\d{1,5})?$/
      break
    case 6:
      regex = /^-?\d+(\.\d{1,6})?$/
      break
    case 7:
      regex = /^-?\d+(\.\d{1,7})?$/
      break
    case 8:
      regex = /^-?\d+(\.\d{1,8})?$/
      break
    case 9:
      regex = /^-?\d+(\.\d{1,9})?$/
      break
    case 10:
      regex = /^-?\d+(\.\d{1,10})?$/
      break
  }
  return regex
}
