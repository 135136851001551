<template>
  <div>
    <v-file-input
      v-show="!readOnly"
      v-model="localValue"
      type="file"
      :webkitdirectory="uploadFolder"
      :multiple="multiple"
      :label="processReplacements(formItem.title)"
      prepend-icon="folder"
      @change="handleFileInput"
    />
  </div>
</template>

<script>
import { mixinFormItemGeneric } from '../mixins/mixin-form-item-generic'

export default {
  mixins: [mixinFormItemGeneric],
  computed: {
    uploadFolder() {
      if (
        Object.hasOwnProperty.call(this.formItem, 'options') &&
        Object.hasOwnProperty.call(this.formItem.options, 'folder')
      ) {
        return this.formItem.options.folder
      }
      return false
    },
    /**
     * Whether the upload form accepts multiple files. Defaults to false
     */
    multiple() {
      try {
        return this.formItem.options.multiple || false
      } catch (e) {
        return false
      }
    },
  },
  methods: {
    handleFileInput($event) {
      // eslint-disable-next-line
      console.log('$event', $event)
    },
  },
}
</script>
