export const debugSettings = {
  title: 'Debug Settings',
  formName: 'debugSettings',
  allPanelsOpen: true,
  // customOpenPanels: [1],
  introText: '',
  panels: [
    {
      id: 1,
      title: 'Debug tools',
      formItems: [
        {
          id: 10,
          type: 'actionButton',
          title: 'Initialize All Settings',
          action: 'AsfForms/resetAllSettings',
          options: {
            block: true,
          },
        },
        // {
        //   id: 20,
        //   type: "checkbox",
        //   title: "Show debug bar",
        //   default: false,
        //   value: false,
        //   options: {
        //     settingsKey: "showDebugBar"
        //   }
        // },
        // {
        //   id: 30,
        //   type: "checkbox",
        //   title: "Show mutation snackbar",
        //   default: false,
        //   value: false,
        //   options: {
        //     settingsKey: "showMutationSnackbar"
        //   }
        // },
        // {
        //   id: 40,
        //   type: "checkbox",
        //   title: "Show panel and formItem id's",
        //   default: false,
        //   value: false,
        //   options: {
        //     settingsKey: "showIds"
        //   }
        // },
        // {
        //   id: 50,
        //   type: "checkbox",
        //   title: "Show Panel Debugger",
        //   default: false,
        //   value: false,
        //   options: {
        //     settingsKey: "showPanelData"
        //   }
        // },
        // {
        //   id: 51,
        //   type: "checkbox",
        //   title: "Show FormItem Debugger",
        //   default: false,
        //   value: false,
        //   options: {
        //     settingsKey: "showFormItemData"
        //   }
        // },
        {
          id: 60,
          type: 'select',
          default: '100%',
          value: '100%',
          title: 'Set the application\'s zoom level:',
          items: ['50%', '75%', '85%', '100%', '125%', '150%'],
          options: {
            settingsKey: 'zoomLevel',
          },
        },
      ],
    },
  ],
}
