<template>
  <v-btn
    :block="block"
    class="primary mb-5"
    @click.stop="handleActionButtonAction"
  >
    {{ title }}
  </v-btn>
</template>

<script>
import { mixinFormItemGeneric } from '../mixins/mixin-form-item-generic'

export default {
  mixins: [mixinFormItemGeneric],
  methods: {
    handleActionButtonAction() {
      let argument = undefined
      if (this.formItem.options && this.formItem.options.actionArgument) {
        argument = this.formItem.options.actionArgument
      }
      this.$store.dispatch(this.formItem.action, argument)
    },
  },
}
</script>
