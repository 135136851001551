import Vue from 'vue'
import AsfDebugBar from './components/AsfDebugBar'
import AsfLoadingSpinner from './components/AsfLoadingSpinner'
import AsfLoadingSpinnerWrapper from './components/AsfLoadingSpinnerWrapper'
import AsfMutationSnackbar from './components/AsfMutationSnackbar'
import AsfFormWrapper from './components/AsfFormWrapper'
import AsfMultiFormWrapper from './components/AsfMultiFormWrapper'
import AsfFormItemBuilder from './components/form-builder/AsfFormItemBuilder'
import AsfPageFormBuilder from './components/form-builder/AsfPageFormBuilder'
import AsfFormBuilder from './components/form-builder/AsfFormBuilder'
import AsfPanelBuilder from './components/form-builder/AsfPanelBuilder'
import AsfStepperBuilder from './components/form-builder/AsfStepperBuilder'
import AsfActionButton from './components/form-builder/form-items/AsfActionButton'
import AsfActionSwitch from './components/form-builder/form-items/AsfActionSwitch'
import AsfCheckbox from './components/form-builder/form-items/AsfCheckbox'
import AsfInvisibleCheckbox from './components/form-builder/form-items/AsfInvisibleCheckbox'
import AsfDateField from './components/form-builder/form-items/AsfDateField'
import AsfDebug from './components/form-builder/form-items/AsfDebug'
import AsfDebugModal from './components/form-builder/form-items/AsfDebugModal'
import AsfFileInput from './components/form-builder/form-items/AsfFileInput'
import AsfHelpText from './components/form-builder/form-items/AsfHelpText'
import AsfHelpTextHtml from './components/form-builder/form-items/AsfHelpTextHtml'
import AsfMatrixSelectCore from './components/form-builder/form-items/MatrixSelector/AsfMatrixSelectCore'
import AsfMatrixSelect from './components/form-builder/form-items/AsfMatrixSelect'
import AsfMinMaxNumericInput from './components/form-builder/form-items/AsfMinMaxNumericInput'
import AsfMultiColumnSelect from './components/form-builder/form-items/AsfMultiColumnSelect'
import AsfNumericField from './components/form-builder/form-items/AsfNumericField'
import AsfPanelMenuSelector from './components/form-builder/form-items/AsfPanelMenuSelector'
import AsfRadio from './components/form-builder/form-items/AsfRadio'
import AsfSelect from './components/form-builder/form-items/AsfSelect'
import AsfSelectColour from './components/form-builder/form-items/AsfSelectColour'
import AsfSelectObject from './components/form-builder/form-items/AsfSelectObject'
import AsfTextArea from './components/form-builder/form-items/AsfTextArea'
import AsfTextField from './components/form-builder/form-items/AsfTextField'
import AsfTextFieldPassword from './components/form-builder/form-items/AsfTextFieldPassword'
import AsfSharedDataTester from './components/form-builder/form-items/AsfSharedDataTester'
import AsfSettingsPages from './views/AsfSettingsPages'
import AsfManualPages from './views/AsfManualPages'

Vue.component('AsfDebugBar', AsfDebugBar)
Vue.component('AsfLoadingSpinner', AsfLoadingSpinner)
Vue.component('AsfLoadingSpinnerWrapper', AsfLoadingSpinnerWrapper)
Vue.component('AsfMutationSnackbar', AsfMutationSnackbar)
Vue.component('AsfFormWrapper', AsfFormWrapper)
Vue.component('AsfMultiFormWrapper', AsfMultiFormWrapper)
Vue.component('AsfFormItemBuilder', AsfFormItemBuilder)
Vue.component('AsfFormBuilder', AsfFormBuilder)
Vue.component('AsfPageFormBuilder', AsfPageFormBuilder)
Vue.component('AsfPanelBuilder', AsfPanelBuilder)
Vue.component('AsfStepperBuilder', AsfStepperBuilder)
Vue.component('AsfActionButton', AsfActionButton)
Vue.component('AsfActionSwitch', AsfActionSwitch)
Vue.component('AsfCheckbox', AsfCheckbox)
Vue.component('AsfInvisibleCheckbox', AsfInvisibleCheckbox)
Vue.component('AsfDateField', AsfDateField)
Vue.component('AsfDebug', AsfDebug)
Vue.component('AsfDebugModal', AsfDebugModal)
Vue.component('AsfFileInput', AsfFileInput)
Vue.component('AsfHelpText', AsfHelpText)
Vue.component('AsfHelpTextHtml', AsfHelpTextHtml)
Vue.component('AsfMatrixSelect', AsfMatrixSelect)
Vue.component('AsfMatrixSelectCore', AsfMatrixSelectCore)
Vue.component('AsfMinMaxNumericInput', AsfMinMaxNumericInput)
Vue.component('AsfMultiColumnSelect', AsfMultiColumnSelect)
Vue.component('AsfNumericField', AsfNumericField)
Vue.component('AsfPanelMenuSelector', AsfPanelMenuSelector)
Vue.component('AsfRadio', AsfRadio)
Vue.component('AsfSelect', AsfSelect)
Vue.component('AsfSelectColour', AsfSelectColour)
Vue.component('AsfSelectObject', AsfSelectObject)
Vue.component('AsfTextArea', AsfTextArea)
Vue.component('AsfTextField', AsfTextField)
Vue.component('AsfTextFieldPassword', AsfTextFieldPassword)
Vue.component('AsfSharedDataTester', AsfSharedDataTester)
Vue.component('AsfSettingsPages', AsfSettingsPages)
Vue.component('AsfManualPages', AsfManualPages)

export const registerCoreFormItems = [
  { moduleName: 'AsfActionButton', formItemType: 'actionButton' },
  { moduleName: 'AsfActionSwitch', formItemType: 'actionSwitch' },
  { moduleName: 'AsfCheckbox', formItemType: 'checkbox' },
  { moduleName: 'AsfInvisibleCheckbox', formItemType: 'invisibleCheckbox' },
  { moduleName: 'AsfDateField', formItemType: 'dateField' },
  { moduleName: 'AsfFileInput', formItemType: 'fileInput' },
  { moduleName: 'AsfHelpText', formItemType: 'helpText' },
  { moduleName: 'AsfHelpTextHtml', formItemType: 'helpTextHtml' },
  { moduleName: 'AsfMatrixSelect', formItemType: 'matrixSelect' },
  { moduleName: 'AsfMinMaxNumericInput', formItemType: 'minMaxNumericField' },
  { moduleName: 'AsfMultiColumnSelect', formItemType: 'multiColumnSelect' },
  { moduleName: 'AsfNumericField', formItemType: 'numericField' },
  { moduleName: 'AsfPanelMenuSelector', formItemType: 'panelMenuSelector' },
  { moduleName: 'AsfRadio', formItemType: 'radio' },
  { moduleName: 'AsfSelect', formItemType: 'select' },
  { moduleName: 'AsfSelectColour', formItemType: 'selectColour' },
  { moduleName: 'AsfSelectObject', formItemType: 'selectObject' },
  { moduleName: 'AsfTextArea', formItemType: 'textArea' },
  { moduleName: 'AsfTextField', formItemType: 'textField' },
  { moduleName: 'AsfTextFieldPassword', formItemType: 'textFieldPassword' },
  { moduleName: 'AsfSharedDataTester', formItemType: 'sharedDataTester' },
]
