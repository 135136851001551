export const projectSettings = {
  title: 'Project Settings',
  formName: 'projectSettings',
  isSettingsForm: true,
  allPanelsOpen: false,
  customOpenPanels: [0],
  introText: '',
  panels: [
    {
      id: 10,
      title: 'Project name settings',
      show: {
        value: true,
      },
      formItems: [
        {
          id: 10,
          type: 'textField',
          title: 'Project name:',
          default: '',
          value: '',
          show: {
            value: true,
          },
        },
        {
          id: 20,
          type: 'textArea',
          title: 'Project Comments:',
          default: '',
          value: '',
          show: {
            value: true,
          },
        },
        {
          id: 30,
          type: 'textField',
          title: 'String replacement for \'Option\':',
          default: 'Option',
          value: 'Option',
          show: {
            value: true,
          },
          options: {
            settingsKey: 'customNameForOption',
          },
        },
        {
          id: 40,
          debug: true,
          type: 'textField',
          title: 'String replacement for \'optie\':',
          default: 'optie',
          value: 'optie',
          show: {
            value: true,
          },
          options: {
            settingsKey: 'customNameForoptie',
          },
        },
      ],
    },
  ],
}
