<template>
  <div>
    <asf-panel-builder
      v-if="formConfig.type !== 'stepper'"
      ref="formBuilder"
      :form-config="formConfig"
      :panels="panelsRenderedWithConditionalLogic"
      :all-panels-open="formConfig.allPanelsOpen"
      :custom-open-panels="formConfig.customOpenPanels"
      :form-name="formConfig.formName"
      @updateFormValidity="updateFormValidity"
    >
      <template #submitArea>
        <slot name="submitArea" />
      </template>
    </asf-panel-builder>
    <asf-stepper-builder
      v-if="formConfig.type === 'stepper'"
      :form-config="formConfig"
      :panels="panelsRenderedWithConditionalLogic"
      :all-panels-open="formConfig.allPanelsOpen"
      :custom-open-panels="formConfig.customOpenPanels"
      :form-name="formConfig.formName"
    />
  </div>
</template>

<script>
export default {
  props: {
    formConfig: {
      type: Object,
      required: true,
    },
    canExport: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    panelsRenderedWithConditionalLogic() {
      return this.$store.getters['AsfForms/conditionalPanels'](
        this.formConfig.formName,
      )
    },
    generateExportButtonActive() {
      return this.canExport
    },
  },
  watch: {},
  beforeMount() {
    this.$store.dispatch('AsfForms/setCurrentPath', this.formConfig.formName)
  },
  mounted() {
    this.handleFormInit()
  },
  methods: {
    exportData() {
      this.$store.dispatch('AsfForms/exportData', this.formConfig.formName)
    },
    handleFormInit() {
      if (
        Object.hasOwnProperty.call(this.formConfig, 'init') &&
        Object.hasOwnProperty.call(this.formConfig.init, 'action') &&
        Object.hasOwnProperty.call(this.formConfig.init.action, 'storeAction')
      ) {
        if (
          Object.hasOwnProperty.call(
            this.formConfig.init.action,
            'actionArgument',
          )
        ) {
          this.$store.dispatch(
            this.formConfig.init.action.storeAction,
            this.formConfig.init.action.actionArgument,
          )
        } else {
          this.$store.dispatch(this.formConfig.init.action.storeAction)
        }
      }
    },
    updateFormValidity(value) {
      this.$emit('updateFormValidity', value)
    },
  },
}
</script>

<style lang="scss" scoped></style>
