const developmentOverview = {
  title: 'Overview of all the components',
  formName: 'developmentOverview',
  useExpansionPanels: true,
  allPanelsOpen: false,
  readOnly: false,
  introText:
    'This form shows an overview of all the components that are available, to watch the settings for the individual items goto: <br /> <strong>Settings > Debug > Show FormItem Data</strong>',
  init: {
    action: {
      storeAction: 'AsfMessages/showMessageInSnackbar',
      actionArgument:
        'This is an example on how to trigger an action on form initialisation',
    },
  },
  panels: [
    {
      id: 10,
      title: 'Radio Selector',
      debug: true,
      show: {
        value: true,
      },
      formItems: [
        {
          id: 1,
          type: 'radio',
          title: 'Select one of the fields:',
          default: 1,
          value: 1,
          items: [
            {
              id: 1,
              title: 'Option A',
            },
            {
              id: 2,
              title: 'Option B',
            },
            {
              id: 3,
              title: 'Option C',
            },
          ],
          options: {
            formVariableKey: 'someKey',
          },
        },
      ],
    },
    {
      id: 20,
      title: 'Radio Selector with getter',
      formItems: [
        {
          id: 1,
          type: 'radio',
          title: 'Select one of the fields:',
          default: 3,
          value: 3,
          fetchItems: {
            getter: 'AsfForms/testData',
          },
        },
      ],
    },
    {
      id: 22,
      title: 'Dropdown Selectors',
      formItems: [
        {
          id: 5,
          type: 'select',
          title: 'Select one:',
          items: ['Wouter', 'Jelmer', 'Marcel', 'Jochem', 'Joris'],
          default: 'Wouter',
          value: 'Wouter',
          readOnly: false,
          options: {
            hint: 'Dit is een hint text',
          },
        },
        {
          id: 6,
          type: 'select',
          title: 'Select multiple:',
          items: ['Wouter', 'Jelmer', 'Marcel', 'Jochem', 'Joris'],
          default: [],
          value: [],
          options: {
            hint: 'Dit is een hint',
            multiple: true,
          },
        },
        {
          id: 7,
          type: 'selectObject',
          title: '`Select one (from Object):`',
          items: [
            {
              id: 1,
              title: 'Some long value A',
            },
            {
              id: 2,
              title: 'Some long value B',
            },
            {
              id: 3,
              title: 'Some long value C',
            },
          ],
          default: 1,
          value: 1,
          options: {
            hint: 'Dit is een hint tekst',
            itemText: 'title',
            itemValue: 'id',
          },
        },
        {
          id: 8,
          type: 'selectObject',
          title: 'Select multiple (Objects):',
          items: [
            {
              code: 'FF0000',
              color: 'Red',
            },
            {
              code: '008000',
              color: 'Green',
            },
            {
              code: 'FFFF00',
              color: 'Yellow',
            },
            {
              code: '800080',
              color: 'Purple',
            },
          ],
          default: null,
          value: null,
          options: {
            multiple: true,
            hint: 'Dit is een hint',
            itemText: 'color',
            itemValue: 'code',
          },
        },
        {
          id: 9,
          type: 'selectObject',
          title: 'Select single (Getter):',
          default: 1,
          value: 1,
          fetchItems: {
            getter: 'AsfForms/testData',
          },
          options: {
            hint: 'Dit is een hint',
            // options below are optional:
            // itemText: "name",
            // itemValue: "id"
          },
        },
        {
          id: 10,
          type: 'selectObject',
          title: 'Select multiple (Getter):',
          default: [1, 2, 3],
          value: [1, 2, 3],
          fetchItems: {
            getter: 'AsfForms/testData',
          },
          options: {
            multiple: true,
            hint: 'Dit is een hint',
            // options below are optional:
            // itemText: "name",
            // itemValue: "id"
          },
        },
      ],
    },
    {
      id: 24,
      title: 'Panel Menu Selector',
      show: {
        value: true,
      },
      formItems: [
        {
          id: 10,
          type: 'panelMenuSelector',
          title: 'Select one of the options below:',
          default: {},
          value: {},
          menus: [
            {
              id: 1,
              title: 'Some options A',
              items: [
                { id: 1, title: 'Keuze 1' },
                { id: 2, title: 'Keuze 2' },
                { id: 3, title: 'Keuze 3' },
                { id: 4, title: 'Keuze 4' },
                { id: 5, title: 'Keuze 5' },
              ],
            },
            {
              id: 2,
              title: 'Some options B',
              items: [
                { id: 6, title: 'Keuze 6' },
                { id: 7, title: 'Keuze 7' },
                { id: 8, title: 'Keuze 8' },
                { id: 9, title: 'Keuze 9' },
              ],
            },
            {
              id: 3,
              title: 'Some options C',
              items: [
                { id: 10, title: 'Keuze 10' },
                { id: 11, title: 'Keuze 11' },
                { id: 12, title: 'Keuze 12' },
                { id: 13, title: 'Keuze 13' },
                { id: 14, title: 'Keuze 14' },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 26,
      title: 'Select colour',
      formItems: [
        {
          id: 10,
          type: 'selectColour',
          title: 'Select a colour:',
          items: [
            'aquamarine',
            'blue',
            'brown',
            'cyan',
            'darkorange',
            'darkorchid',
            'darkviolet',
            'deepskyblue',
            'gold',
            'green',
            'grey',
            'yellow',
            'white',
            'red',
            'purple',
            'lightblue',
            'lightskyblue',
          ],
          default: 'blue',
          value: 'blue',
        },
      ],
    },
    {
      id: 30,
      title: 'Checkbox',
      show: {
        value: true,
      },
      formItems: [
        {
          id: 1,
          type: 'checkbox',
          title: 'I agree to disagree',
          default: false,
          value: false,
        },
      ],
    },
    {
      id: 35,
      title: 'Checkboxes multi column (optional getter)',
      show: {
        value: true,
      },
      formItems: [
        {
          id: 10,
          debug: false,
          type: 'multiColumnSelect',
          title: 'Select one of the options below:',
          default: null,
          value: null,
          items: [
            { id: 1, title: 'Option 1', value: false },
            { id: 2, title: 'Option 2', value: false },
            { id: 3, title: 'Option 3', value: false },
            { id: 4, title: 'Option 4', value: false },
            { id: 5, title: 'Option 5', value: false },
            { id: 6, title: 'Option 6', value: false },
            { id: 7, title: 'Option 7', value: false },
            { id: 8, title: 'Option 8', value: false },
            { id: 9, title: 'Option 9', value: false },
            { id: 10, title: 'Option 10', value: false },
            { id: 11, title: 'Option 11', value: false },
            { id: 12, title: 'Option 12', value: false },
            { id: 13, title: 'Option 13', value: false },
            { id: 14, title: 'Option 14', value: false },
          ],
        },
      ],
    },
    {
      id: 36,
      title: 'Checkbox required (for agreements)',
      show: {
        value: true,
      },
      formItems: [
        {
          id: 1,
          type: 'checkbox',
          title: 'I agree to disagree',
          default: false,
          value: false,
          options: {
            hint: 'You have to agree!',
          },
          validation: {
            required: true,
          },
        },
      ],
    },
    {
      id: 50,
      title: 'Text field',
      show: {
        value: true,
      },
      formItems: [
        {
          id: 2,
          debug: false,
          type: 'textField',
          title: 'Fill in some text:',
          default: null,
          value: null,
          options: {
            clearable: true,
          },
          validation: {
            length: 11,
            required: true,
          },
        },
        {
          id: 3,
          type: 'textField',
          title: 'Fill in some other text:',
          default: '',
          value: '',
          // validation: {
          //   length: 5
          //   required: false
          // },
        },
      ],
    },
    {
      id: 60,
      title: 'Password',
      show: {
        value: true,
      },
      formItems: [
        {
          id: 1,
          type: 'textFieldPassword',
          title: 'Fill in your password:',
          default: '',
          value: '',
          options: {
            clearable: true,
          },
          validation: {
            length: 11,
            required: true,
          },
        },
      ],
    },
    {
      id: 70,
      title: 'Text area',
      show: {
        value: true,
      },
      formItems: [
        {
          id: 1,
          type: 'textArea',
          debug: true,

          title: 'Fill in some text:',
          default: '',
          value: 'Here\'s some text to get you started',
          options: {
            clearable: true,
          },
          validation: {
            length: 150,
            required: true,
          },
        },
      ],
    },
    {
      id: 80,
      title: 'Numeric fields',
      formItems: [
        {
          id: 10,
          type: 'numericField',
          title: 'Numeric field integer between 0 - 4:',
          default: 1,
          value: 1,
          validation: {
            isInteger: true,
            minimum: 0,
            maximum: 4,
            required: true,
          },
          show: {
            value: true,
          },
        },
        {
          id: 20,
          type: 'numericField',
          title: 'Numeric field float with precision 2 and between 0 - 4:',
          default: 0.01,
          value: 0.01,
          validation: {
            isFloat: true,
            precision: 2,
            minimum: 0,
            maximum: 4,
            required: true,
          },
          show: {
            value: true,
          },
        },
        {
          id: 30,
          type: 'numericField',
          title: 'Numeric field float with precision 7 and between 0 - 4:',
          default: 0.01,
          value: 0.01,
          validation: {
            isFloat: true,
            precision: 7,
            minimum: 0,
            maximum: 4,
            required: true,
          },
          show: {
            value: true,
          },
        },
      ],
    },
    {
      id: 90,
      title: 'Minimum Maximum Numeric Input',
      formItems: [
        {
          id: 30,
          type: 'minMaxNumericField',
          title:
            'Numeric field  implicit float with endless precision and between 0 - 4:',
          default: {
            min: 1,
            max: 100,
          },
          label: {
            titleMin: 'Enter the minimum value',
            titleMax: 'Enter the maximum value',
          },
          value: {
            min: 1,
            max: 100,
          },
        },
      ],
    },
    {
      id: 100,
      title: 'File and Folder input',
      formItems: [
        {
          id: 10,
          type: 'fileInput',
          title: 'Select a file to upload here:',
          default: [],
          value: [],
        },
        {
          id: 20,
          type: 'fileInput',
          title: 'Select a folder to upload here:',
          default: [],
          value: [],
          options: {
            folder: true,
          },
        },
      ],
    },
    {
      id: 110,
      title: 'Date Field',
      formItems: [
        {
          id: 10,
          type: 'dateField',
          default: '',
          value: '',
        },
      ],
    },
    {
      id: 130,
      title: 'Action Buttons',
      formItems: [
        {
          id: 10,
          type: 'actionButton',
          title: 'Do something',
          action: 'AsfForms/exampleAction',
        },
        {
          id: 20,
          type: 'actionButton',
          title: 'Please do something',
          action: 'AsfForms/exampleAction',
          options: {
            block: true,
          },
        },
      ],
    },
    {
      id: 300,
      title: 'Matrix Editors',
      show: {
        value: true,
      },
      formItems: [
        {
          id: 10,
          type: 'matrixSelect',
          name: 'UseCamelCasedName',
          default: {},
          value: {},
          itemName: {
            single: 'record',
            multi: 'records',
          },
          items: [
            {
              id: 1,
              name: 'Some Record 1 MI',
            },
            {
              id: 2,
              name: 'Some Record 2 IA',
            },
            {
              id: 3,
              name: 'Some Record 3 NM',
            },
          ],
          selectables: [
            {
              headerText: 'Mag Iets',
              columnName: 'MI',
            },
            {
              headerText: 'Iets anders',
              columnName: 'IA',
            },
            {
              headerText: 'Nog meer',
              columnName: 'NM',
            },
          ],
        },
        {
          id: 20,
          type: 'matrixSelect',
          name: 'UseCamelCasedOtherName',
          default: {},
          value: {},
          options: {
            useNamingConventions: true,
          },
          itemName: {
            single: 'record',
            multi: 'records',
          },
          items: [
            {
              id: 1,
              name: 'Some Record 1 MI',
            },
            {
              id: 2,
              name: 'Some Record 2 IA',
            },
            {
              id: 3,
              name: 'Some Record 3 NM',
            },
          ],
          // fetchItems: {
          //   getter: "Project/ListOfPhases",
          //   dependencies: true
          // },
          selectables: [
            {
              headerText: 'Mag Iets',
              columnName: 'MI',
              namingConvention: 'MI',
            },
            {
              headerText: 'Iets anders',
              columnName: 'IA',
              namingConvention: 'IA',
            },
            { headerText: 'Nog meer', columnName: 'NM', namingConvention: 'NM' },
          ],
        },
        {
          id: 30,
          type: 'matrixSelect',
          name: 'UseCamelCasedOtherName2',
          default: {},
          value: {},
          options: {
            addTextFilter: true,
            useNamingConventions: true,
          },
          itemName: {
            single: 'record',
            multi: 'records',
          },
          fetchItems: {
            getter: 'AsfForms/testData',
          },
          tempData:
            '[{"id":1,"name":"eerste optie","show":true,"MI":true},{"id":2,"name":"tweede optie","show":true,"MI":true},{"id":3,"name":"derde optie","show":true,"MI":true},{"id":4,"name":"vierde optie","show":true},{"id":5,"name":"vijfde optie","show":true}]',
          selectables: [
            {
              headerText: 'Mag Iets',
              columnName: 'MI',
              namingConvention: 'MI',
            },
            {
              headerText: 'Iets anders',
              columnName: 'IA',
              namingConvention: 'IA',
            },
            { headerText: 'Nog meer', columnName: 'NM', namingConvention: 'NM' },
          ],
        },
      ],
    },
    {
      id: 400,
      title: 'Help text component',
      show: {
        value: true,
      },
      formItems: [
        {
          id: 1,
          type: 'helpText',
          icon: 'mdi-lightbulb',
          color: 'secondary',
          title: 'This is a helper to add context to forms',
        },
        {
          id: 2,
          type: 'helpText',
          icon: 'mdi-alert-circle-outline',
          color: 'error',
          title: 'This is a helper indicating an error to add context to forms',
        },
        {
          id: 3,
          type: 'helpText',
          icon: 'mdi-check-bold',
          color: 'success',
          title: 'This is a helper indicating success to add context to forms',
        },
        {
          id: 4,
          type: 'helpText',
          icon: 'mdi-lightbulb',
          outlined: true,
          color: 'secondary',
          title: 'This is a helper to add context to forms',
        },
        {
          id: 5,
          type: 'helpText',
          icon: 'mdi-alert-circle-outline',
          outlined: true,
          color: 'error',
          title: 'This is a helper indicating an error to add context to forms',
        },
        {
          id: 6,
          type: 'helpText',
          icon: 'mdi-check-bold',
          outlined: true,
          color: 'success',
          title: 'This is a helper indicating success to add context to forms',
        },
      ],
    },
  ],
}

export { developmentOverview }
