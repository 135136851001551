<template>
  <v-form
    ref="form"
    v-model="valid"
  >
    <!--
    * useExpansionPanels enables rendering the formConfig's panels
    * as vuetify expansion panels (enabling the user to collapse panel content)
    * the template below gives an alternative by displaying the panel
    * header and content as static (non collapsible) cards
    -->

    <div v-if="!useExpansionPanels">
      <transition-group
        :style="`width: 100%; ${setStyleGridParent(formConfig)}`"
        name="fade"
      >
        <v-card
          v-for="panel in panels"
          v-show="panel.show.value === true"
          :key="panel.id"
          :class="nestedProperty(panel, 'layout', 'class')"
          :style="setStyleGridItem(panel)"
          flat
        >
          <v-card-title style="word-break: break-word">
            {{ panel.title }}
            <div
              v-if="$asf.debugging.showPanelAndFormIds"
              class="ml-2 text-caption"
            >
              panel.id = {{ panel.id }}
            </div>
            <div style="position: absolute; right: 52px">
              <asf-debug-modal
                v-if="showDebugger(panel)"
                :show-data="panel"
                :form-name="formName"
                :panel-id="panel.id"
              />
            </div>
          </v-card-title>
          <v-card-text>
            <transition-group
              name="fade"
              :style="setStyleGridParent(panel)"
            >
              <asf-form-item-builder
                v-for="formItem in panel.formItems"
                v-show="formItem.show.value === true"
                :key="formItem.id"
                :style="setStyleGridItem(formItem)"
                :read-only="setReadOnly(formItem, panel)"
                :form-item="formItem"
                :form-config="formConfig"
                :form-name="formName"
                :panel-id="panel.id"
                :panel-visible="panel.show.value"
                class="text-h6"
                @itemUpdated="updateFormItem"
                @tempDataUpdated="updateFormItemTempData"
                @metaDataUpdated="updateFormItemMetaData"
                @valueObjectUpdated="updateFormItemValueObject"
                @valueObjectsUpdated="updateFormItemValueObjects"
              />
            </transition-group>
          </v-card-text>
        </v-card>
      </transition-group>
    </div>

    <!--
    * useExpansionPanels enables rendering the formConfig's panels
    * as vuetify expansion panels (enabling the user to collapse panel content)
    -->

    <v-expansion-panels
      v-if="useExpansionPanels"
      v-model="openPanels"
      accordion
      hover
      multiple
      flat
    >
      <transition-group
        :style="`width: 100%; ${setStyleGridParent(formConfig)}`"
        name="fade"
      >
        <v-expansion-panel
          v-for="panel in panels"
          v-show="panel.show.value === true"
          :key="panel.id"
          :class="nestedProperty(panel, 'layout', 'class')"
          :style="setStyleGridItem(panel)"
        >
          <v-expansion-panel-header class="text-h6">
            {{ panel.title }}
            <div
              v-if="$asf.debugging.showPanelAndFormIds"
              class="ml-2 text-caption"
            >
              panel.id = {{ panel.id }}
            </div>
            <div style="position: absolute; right: 52px">
              <asf-debug-modal
                v-if="showDebugger(panel)"
                :show-data="panel"
                :form-name="formName"
                :panel-id="panel.id"
              />
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <transition-group
              name="fade"
              :style="setStyleGridParent(panel)"
            >
              <asf-form-item-builder
                v-for="formItem in panel.formItems"
                v-show="formItem.show.value === true"
                :key="formItem.id"
                :style="setStyleGridItem(formItem)"
                :read-only="setReadOnly(formItem, panel)"
                :form-item="formItem"
                :form-name="formName"
                :form-config="formConfig"
                :panel-id="panel.id"
                :panel-visible="panel.show.value"
                class="text-h6"
                @itemUpdated="updateFormItem"
                @tempDataUpdated="updateFormItemTempData"
                @metaDataUpdated="updateFormItemMetaData"
                @valueObjectUpdated="updateFormItemValueObject"
                @valueObjectsUpdated="updateFormItemValueObjects"
              />
            </transition-group>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </transition-group>
    </v-expansion-panels>
    <slot name="submitArea" />
  </v-form>
</template>

<script>
import { nestedProperty } from '../../helpers/nested-property'

export default {
  props: {
    formName: {
      type: String,
      required: true,
    },
    formConfig: {
      type: Object,
      required: true,
    },
    customOpenPanels: {
      type: Array,
      default: () => [],
    },
    allPanelsOpen: {
      type: Boolean,
      default: false,
    },
    panels: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      openPanels: this.customOpenPanels,
      valid: true,
    }
  },
  computed: {
    useExpansionPanels() {
      return nestedProperty(this.formConfig, 'useExpansionPanels') || false
    },
    settingsVariables() {
      return nestedProperty(this.$store.state, "AsfForms", "settingsVariables");
    },
  },
  watch: {
    valid(value) {
      this.$emit('updateFormValidity', value)
    },
  },
  beforeMount() {
    if (this.allPanelsOpen === true && this.customOpenPanels.length === 0) {
      this.openAllPanels()
    }
    this.$store.dispatch('AsfForms/setCurrentPath', this.formConfig.formName)
  },
  methods: {
    nestedProperty,
    updateFormItem(valueInContext) {
      valueInContext.formName = this.formName
      this.$store.dispatch('AsfForms/setDataFormItem', valueInContext)
    },
    updateFormItemTempData(valueInContext) {
      valueInContext.formName = this.formName
      this.$store.dispatch('AsfForms/setTempDataFormItem', valueInContext)
    },
    updateFormItemMetaData(valueInContext) {
      valueInContext.formName = this.formName
      this.$store.dispatch('AsfForms/setMetaDataFormItem', valueInContext)
    },
    updateFormItemValueObject(valueInContext) {
      valueInContext.formName = this.formName
      this.$store.dispatch('AsfForms/setValueObjectFormItem', valueInContext)
    },
    updateFormItemValueObjects(valueInContext) {
      valueInContext.formName = this.formName
      this.$store.dispatch('AsfForms/setValueObjectsFormItem', valueInContext)
    },
    openAllPanels() {
      let iterator = 0
      this.openPanels = []
      this.panels.forEach(() => {
        this.openPanels.push(iterator)
        iterator++
      })
    },
    showDebugger(panel) {
      if (Object.hasOwnProperty.call(panel, 'debug') && panel.debug) {
        return true
      }

      if (Object.hasOwnProperty.call(this.settingsVariables, 'showPanelData')) {
        return this.settingsVariables.showPanelData
      }
      return false
    },
    setStyleGridParent(parentItem) {
      if (
        !Object.hasOwnProperty.call(parentItem, 'layout') ||
        !Object.hasOwnProperty.call(parentItem.layout, 'useGrid') ||
        parentItem.layout.useGrid === false ||
        this.$vuetify.breakpoint.smAndDown
      ) {
        return null
      }

      const gridDefinition = this.initializeGridDefinition(parentItem)
      return this.setGridStyle(gridDefinition)
    },
    setStyleGridItem(gridItem) {
      if (
        !Object.hasOwnProperty.call(gridItem, 'layout') ||
        !Object.hasOwnProperty.call(gridItem.layout, 'gridArea') ||
        this.$vuetify.breakpoint.smAndDown
      ) {
        return null
      }
      return `grid-area: ${gridItem.layout.gridArea};`
    },
    initializeGridDefinition(parentItem) {
      const defaultGridDefinition = {
        gridTemplateColumns: 'repeat(4, 1fr)',
        gridTemplateRows: '1fr',
        gridColumnGap: '6px',
        gridRowGap: '5px',
      }

      if (!Object.hasOwnProperty.call(parentItem.layout, 'gridDefinition')) {
        return this.setGridStyle(defaultGridDefinition)
      }

      const newDef = defaultGridDefinition
      const panDef = parentItem.layout.gridDefinition

      Object.keys(newDef).forEach((key) => {
        if (Object.hasOwnProperty.call(panDef, key)) {
          newDef[key] = panDef[key]
        }
      })

      return newDef
    },
    setGridStyle(definition) {
      return 'display: grid;' +
          `grid-template-columns: ${definition.gridTemplateColumns};` +
          `grid-template-rows: ${definition.gridTemplateRows};` +
          `grid-column-gap: ${definition.gridColumnGap};` +
          `grid-row-gap: ${definition.gridRowGap};`
    },
    setReadOnly(formItem, panel) {
      // Cascade readOnly
      return (
          this.formConfig.readOnly === true ||
          panel.readOnly === true ||
          formItem.readOnly === true
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.v-expansion-panel--active:not(:first-child),
.v-expansion-panel--active + .v-expansion-panel {
  margin-top: 0;
}
</style>
