const { USAGE } = require('../../valueholders/usage')

const defaultValidationForTypeString = ({ formItem, model }) => {
  const isOfTypeString = model[formItem.map].dataType === 'string'
  const validationKeys = Object.keys(formItem.validation)
  const containsStringValidation =
    validationKeys.includes('oneLineAllowedCharactersOnly') ||
    validationKeys.includes('multiLineAllowedCharactersOnly')
  const containsMaximumLength = validationKeys.includes('maximumLength')

  if (isOfTypeString && !containsStringValidation) {
    formItem.validation.oneLineAllowedCharactersOnly = true
    if (!containsMaximumLength) {
      formItem.validation.maximumLength = 128
    }
  }

  return formItem
}

const defaultValidationForTypeObject = ({ formItem, model }) => {
  const validationKeys = Object.keys(formItem.validation)
  const isOfTypeObject = model[formItem.map].dataType === 'object'

  // Backend Validation only


  if (this.useIn === USAGE.BACKEND && isOfTypeObject) {
    const containsStringValueValidation = validationKeys.includes(
      'stringValue',
    )

    if (!containsStringValueValidation) {
      formItem.validation.stringValue = {}
    }
    const keysStringValue = Object.keys(formItem.validation.stringValue)

    const stringValueContainsStringValidation =
      keysStringValue.includes('oneLineAllowedCharactersOnly') ||
      keysStringValue.includes('multiLineAllowedCharactersOnly')

    if (!stringValueContainsStringValidation) {
      formItem.validation.stringValue.oneLineAllowedCharactersOnly = true
    }

    const stringValueContainsMaximumLength = keysStringValue.includes(
      'maximumLength',
    )

    if (!stringValueContainsMaximumLength) {
      formItem.validation.stringValue.maximumLength = 128
    }

    const containsIdValueValidation = validationKeys.includes('idValue')

    if (!containsIdValueValidation) {
      formItem.validation.idValue = {}
    }
    const keysIdValue = Object.keys(formItem.validation.idValue)

    const idValueContainsNumberOrStringValidation =
      keysIdValue.includes('isInteger') ||
      keysIdValue.includes('oneLineAllowedCharactersOnly')

    if (!idValueContainsNumberOrStringValidation) {
      formItem.validation.idValue.isInteger = true
    }
  }
  return formItem
}

module.exports.defaultValidationRulesForFormItems = [
  defaultValidationForTypeString,
  defaultValidationForTypeObject,
]
