<template>
  <v-container>
    <v-card
      v-for="item in localItems"
      :id="`${id}_checkbox_${item.id}`"
      :key="item.id"
      flat
      class="flex-wrap"
    >
      <v-checkbox
        v-model="item.value"
        class="ml-2 mb-1"
        :label="processReplacements(item.title)"
        :readonly="readOnly"
        hide-details
        style="width: 300px"
        @click="updateParentData"
      >
        <template #label>
          <div v-text="processReplacements(item.title)" />
        </template>
      </v-checkbox>
    </v-card>
    <v-text-field
      ref="hiddenTextField"
      class="mt-2"
      :value="numberOfCheckboxesChecked"
      :rules="rules"
      hidden
    />
  </v-container>
</template>

<script>
import { mixinFormItemGeneric } from '../../asf/stateful-forms'

export default {
  mixins: [mixinFormItemGeneric],
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  computed: {
    numberOfCheckboxesChecked() {
      const filteredArray = this.localItems.filter(localItem => localItem.value === true)
      return filteredArray.length
    },
  },
  beforeMount() {
    this.initialize()
  },
  mounted() {
    // Hide the input of the textField
    this.$refs.hiddenTextField.$refs['input-slot'].style.display = 'none'
  },
  methods: {
    initialize() {
      this.localItems.forEach((item) => {
        item.value = false
      })
      this.$emit('change', this.localItems)
    },
    updateParentData() {
      this.$emit('change', this.localItems)
    },
  },
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
