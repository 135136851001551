import Vue from 'vue'
import { packageForms } from '../data/package-forms'
import { projectSettings } from '../data/settings/project-settings'
import { debugSettings } from '../data/settings/debug-settings'
import { ConditionalPanels } from './logic/forms/conditional-panels'
import { MapFormData } from './logic/forms/map-form-data'
import { storage } from './logic/storage'
import { mapArrayOfStrings } from './logic/helpers'
import { testDataForGetter } from '../data/misc/example-data'

const asfForms = {
  namespaced: true,
  state: {
    currentPath: null,
    forms: {
      ...packageForms,
      projectSettings: null,
      debugSettings: null,
    },
    registerCoreFormItems: {},
    registerCustomFormItems: {},
    packageValidationRuleDefinitions: [],
    customValidationRuleDefinitions: [],
    testDataForGetter: testDataForGetter,
    customRenamables: {},
    settingsVariables: {},
    sharedVariables: {},
  },
  getters: {
    ruleDefinitions: (state) => {
      return [
        ...state.packageValidationRuleDefinitions,
        ...state.customValidationRuleDefinitions,
      ]
    },

    // SettingsVariables are persisted in localStorage

    listenToSettingsKey: (state) => (settingsKey) => {
      return (
        state.settingsVariables[settingsKey] || undefined
      )
    },

    // SharedVariables are set during a browser session
    // and are NOT persisted in localStorage

    getSharedVariable: (state) => (settingsKey) => {
      return (
        state.sharedVariables[settingsKey] || undefined
      )
    },
    conditionalPanels: (state) => (formName) => {
      const panels = state.forms[formName].panels
      const conditionalPanels = new ConditionalPanels(panels)

      // Todo: Make more generic solution
      // this solution is for POC with record mapping via api

      const formNameLowerCase = formName.toLowerCase()

      if (formNameLowerCase.includes('api')) {
        const record = state.workFlowData
        return new MapFormData(conditionalPanels, record)
      }

      return conditionalPanels
    },
    processRenamables: (state) => (stringToBeProcessed) => {
      let value = stringToBeProcessed
      if (stringToBeProcessed) {
        Object.keys(state.customRenamables).forEach((rnKey) => {
          value = value.replace(rnKey, state.customRenamables[rnKey])
        })
      }
      return value
    },
    testData: (state) => {
      return mapArrayOfStrings(state.testDataForGetter)
    },
  },
  mutations: {
    setCurrentPath(state, data) {
      state.currentPath = data
    },
    addApplicationForms(state, formsObject) {
      state.forms = { ...state.forms, ...formsObject }
    },
    setRegisterCoreFormItems(state, register) {
      state.registerCoreFormItems = register
    },
    setRegisterCustomFormItems(state, register) {
      state.registerCustomFormItems = register
    },
    setPackageValidationRuleDefinitions(state, rules) {
      state.packageValidationRuleDefinitions = rules
    },
    setCustomValidationRuleDefinitions(state, rules) {
      state.customValidationRuleDefinitions = rules
    },
    setCustomRenamables(state, renamables) {
      state.customRenamables = renamables
    },
    setFormFetchedFromApi(state, { formName, data }) {
      state.forms[formName] = data
    },
    setFormBypassRequired(state, { formName, byPassRequired = false }) {
      Vue.set(state.forms[formName], 'byPassRequired', byPassRequired)
    },
    setDataFormItem(state, data) {
      const path = state.forms[data.formName]
      const panel = path.panels.find((panel) => panel.id === data.panelId)
      const formItem = panel.formItems.find(
        (formItem) => formItem.id === data.itemId,
      )
      Vue.set(formItem, 'value', data.value)
      if (data.metaData) {
        Vue.set(formItem, 'metaData', data.metaData)
      }
    },
    setTempDataFormItem(state, data) {
      const path = state.forms[data.formName]
      const panel = path.panels.find((panel) => panel.id === data.panelId)
      const formItem = panel.formItems.find(
        (formItem) => formItem.id === data.itemId,
      )
      Vue.set(formItem, 'tempData', data.tempData)
    },
    setMetaDataFormItem(state, data) {
      const path = state.forms[data.formName]
      const panel = path.panels.find((panel) => panel.id === data.panelId)
      const formItem = panel.formItems.find(
        (formItem) => formItem.id === data.itemId,
      )
      Vue.set(formItem, 'metaData', data.metaData)
    },
    setValueObjectFormItem(state, data) {
      const path = state.forms[data.formName]
      const panel = path.panels.find((panel) => panel.id === data.panelId)
      const formItem = panel.formItems.find(
        (formItem) => formItem.id === data.itemId,
      )
      Vue.set(formItem, 'valueObject', data.valueObject)
    },
    setValueObjectsFormItem(state, data) {
      const path = state.forms[data.formName]
      const panel = path.panels.find((panel) => panel.id === data.panelId)
      const formItem = panel.formItems.find(
        (formItem) => formItem.id === data.itemId,
      )
      Vue.set(formItem, 'valueObjects', data.valueObjects)
    },
    setFormVariable: function (state, data) {
      if (
        !Object.prototype.hasOwnProperty.call(
          state.forms[data.formName],
          'formVariables',
        )
      ) {
        Vue.set(state.forms[data.formName], 'formVariables', {})
      }
      Vue.set(state.forms[data.formName].formVariables, data.key, data.value)
    },
    setSettingsVariable(state, data) {
      Vue.set(state.settingsVariables, data.key, data.value)
    },
    setSharedVariable(state, data) {
      Vue.set(state.sharedVariables, data.key, data.value)
    },
    setProjectSettings(state, data) {
      Vue.set(state.forms, 'projectSettings', data)
    },
    setDebugSettings(state, data) {
      Vue.set(state.forms, 'debugSettings', data)
    },
  },
  actions: {
    exampleAction: function () {
      alert('You just won a million compliments!!')
    },
    resetForm: function ({ state }, formName) {
      const form = state.forms[formName]
      form.panels.forEach((panel) => {
        panel.formItems.forEach((formItem) => {
          formItem.value = formItem.default
        })
      })
    },
    resetAllSettings: function ({ dispatch }) {
      dispatch('resetProjectSettings')
      dispatch('resetDebugSettings')
      dispatch('resetGeneralSettings')
      location.reload()
    },
    setFormBypassRequired({ commit }, { formName, byPassRequired = false }) {
      commit('setFormBypassRequired', { formName, byPassRequired })
    },
    /*
     * Example: this is how you can load a form configuration via an API

     async loadFormConfigurationFromApi({ commit, dispatch }, { formName }) {
      await dispatch("fetchWorkFlowData");

      const response = await axios.post(
        "http://localhost:3000/api/form-structure-data-by-uuid",
        {
          userId: 8,
          uuid: "ec5903ba-f780-443f-8101-ecc67e99f243",
        }
      );
      commit("setFormFetchedFromApi", {
        formName,
        data: response.data.data.data,
      });
    },
    */
    resetProjectSettings: function ({ dispatch }) {
      storage.save('projectSettings', null)
      dispatch('initializeSettingsPath', {
        settingsPath: 'projectSettings',
        initialData: projectSettings,
        mutator: 'setProjectSettings',
      })
    },
    resetDebugSettings: function ({ dispatch }) {
      storage.save('debugSettings', null)
      dispatch('initializeSettingsPath', {
        settingsPath: 'debugSettings',
        initialData: debugSettings,
        mutator: 'setDebugSettings',
      })
    },
    initializeSettings: function ({ dispatch }) {
      dispatch('initializeSettingsPath', {
        settingsPath: 'projectSettings',
        initialData: projectSettings,
        mutator: 'setProjectSettings',
      })
      dispatch('initializeSettingsPath', {
        settingsPath: 'debugSettings',
        initialData: debugSettings,
        mutator: 'setDebugSettings',
      })
    },
    initializeSettingsPath: function ({ commit, dispatch }, data) {
      const storedData = storage.get(data.settingsPath)
      if (storedData) {
        commit(data.mutator, storedData)
      } else {
        commit(data.mutator, data.initialData)
        storage.save(data.settingsPath, data.initialData)
      }
      dispatch('initializeSettingsVariables', data.settingsPath)
    },
    initializeSettingsVariables: function ({ state, dispatch }, settingsPath) {
      state.forms[settingsPath].panels.forEach((panel) => {
        panel.formItems.forEach((formItem) => {
          if (
            Object.prototype.hasOwnProperty.call(formItem, 'options') &&
            Object.prototype.hasOwnProperty.call(
              formItem.options,
              'settingsKey',
            )
          ) {
            const data = {
              key: formItem.options.settingsKey,
              value: formItem.value,
            }
            dispatch('setSettingsVariable', data)
          }
        })
      })
    },
    setDataFormItem({ state, commit, dispatch }, data) {
      commit('setDataFormItem', data)
      const settingsArray = ['projectSettings', 'debugSettings']

      if (settingsArray.includes(data.formName)) {
        storage.save(data.formName, state.forms[data.formName])
      }
      dispatch('AsfMessages/showMessageInSnackbar', data, { root: true })
    },
    setCurrentPath({ commit }, currentPath) {
      commit('setCurrentPath', currentPath)
    },
    setTempDataFormItem({ commit }, data) {
      commit('setTempDataFormItem', data)
    },
    setMetaDataFormItem({ commit }, data) {
      commit('setMetaDataFormItem', data)
    },
    setValueObjectFormItem({ commit, dispatch }, data) {
      commit('setValueObjectFormItem', data)
      dispatch('AsfMessages/showMessageInSnackbar', data, { root: true })
    },
    setValueObjectsFormItem({ commit, dispatch }, data) {
      commit('setValueObjectsFormItem', data)
      dispatch('AsfMessages/showMessageInSnackbar', data, { root: true })
    },
    setFormVariable({ commit }, data) {
      commit('setFormVariable', data)
    },
    setSettingsVariable({ commit }, data) {
      commit('setSettingsVariable', data)
    },
    setSharedVariable({ commit }, data) {
      commit('setSharedVariable', data)
    },
    async exportData(context, formName) {
      // eslint-disable-next-line
      console.log('exportData was called for formName:', formName)
    },
  },
}

export { asfForms }
