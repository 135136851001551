<template>
  <asf-multi-form-wrapper
    :default-form="defaultFormId"
    :forms="settingsForms"
  />
</template>

<script>
export default {
  data() {
    return {
      defaultFormId: 1,
      settingsForms: [
        {
          id: 1,
          title: 'project',
          path: this.$store.state.AsfForms.forms.projectSettings,
        },
        {
          id: 2,
          title: 'debug',
          path: this.$store.state.AsfForms.forms.debugSettings,
        },
      ],
    }
  },
  beforeCreate() {
    this.$store.dispatch('AsfForms/initializeSettings')
  },
}
</script>
