export const switchTextFilter = function() {
  if (this.useTextFilter) {
    this.handleTextFilter()
  } else {
    this.initializeAfterSwitchingOfTextFilter()
  }
  this.setMetaData()
  this.handleChange()
}

export const handleTextFilter = function() {
  if (this.typeOfTextFilter === 'exclude') {
    this.excludeItemsWithStringInName(this.textFilter)
  } else {
    this.includeItemsWithStringInName(this.textFilter)
  }
}

export const initializeAfterSwitchingOfTextFilter = function() {
  if (this.typeOfTextFilter === 'exclude') {
    this.excludeItemsWithStringInName('')
  } else {
    this.includeItemsWithStringInName('')
  }
  this.textFilter = ''
  this.typeOfTextFilter = 'include'
  this.$emit('setTempData', undefined)
  this.initializeLocalSelectables()
  this.initializeLocalItems()
  this.$forceUpdate()
}

export const includeItemsWithStringInName = function(value) {
  this.initializeLocalItems()
  if (value !== '') {
    this.localItems = this.localItems.filter(item => {
      return item.name.includes(value)
    })
  }
}

export const excludeItemsWithStringInName = function(value) {
  this.initializeLocalItems()
  if (value !== '') {
    this.localItems = this.localItems.filter(item => {
      return !item.name.includes(value)
    })
  }
}

export const setMetaData = function() {
  this.$emit('setMetaData', {
    useTextFilter: this.useTextFilter,
    typeOfTextFilter: this.typeOfTextFilter,
    textFilter: this.textFilter,
  })
}
