<template>
  <div class="selectWrapper">
    <v-select
      v-model="localValue"
      :items="localItems"
      :item-text="itemText"
      :item-value="itemValue"
      :rules="rules"
      :multiple="formItem.options ? formItem.options.multiple : null"
      :chips="formItem.options ? formItem.options.multiple : null"
      :deletable-chips="formItem.options ? formItem.options.multiple : null"
      :readonly="readOnly"
      :menu-props="{ maxHeight: '400' }"
      :label="title"
      :hint="formItem.options.hint"
    />
  </div>
</template>

<script>
import { mixinFormItemGeneric } from '../mixins/mixin-form-item-generic'

export default {
  mixins: [mixinFormItemGeneric],
}
</script>

<style lang="scss">
.selectWrapper {
  .v-text-field .v-label {
    top: 2px;
  }
}
</style>
