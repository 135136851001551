import { asfForms } from '../../../store/asf-forms'

export class FormItemValidation {
  constructor(formItem) {
    this.formItem = formItem
    this.rules = []
    this.setRuleDefinitions()
    this.parse()
    return this.rules
  }

  setRuleDefinitions() {
    this.ruleDefinitions = asfForms.getters.ruleDefinitions(asfForms.state)
  }

  parse() {
    // Rules are defined (and argumented) in the validation object of the formItem
    if (!Object.hasOwnProperty.call(this.formItem, 'validation')) {
      return
    }

    // If the rules are not send over the API but rather defined in the 'rules' key
    // of the formItem we have to add them to the rules array generated in this class

    this.addCustomRules()

    // The required checkbox is used for agreement, so if the value of the checkbox is
    // true, the validation passes

    this.parseRequiredCheckbox()

    // We parse a set of customValidationRuleDefinitions here

    this.parseRuleDefinitions(this.ruleDefinitions)
  }

  parseRuleDefinitions(ruleDefinitions) {
    ruleDefinitions.forEach(ruleDefinition => {
      const hasValidationKey = Object.hasOwnProperty.call(
        this.formItem.validation,
        ruleDefinition.validationKey,
      )
      const hasValidationArgumentKey = Object.hasOwnProperty.call(
        this.formItem.validation,
        ruleDefinition.validationArgumentKey,
      )

      // Overrule required

      if (
        hasValidationKey &&
        ruleDefinition.validationKey === 'required' &&
        this.formItem.byPassRequired
      ) {
        return false
      }

      if (hasValidationKey && hasValidationArgumentKey) {
        const argumentKey = this.formItem.validation[
          ruleDefinition.validationArgumentKey
        ]

        this.rules.push(ruleDefinition.ruleFunction(argumentKey))
      } else if (hasValidationKey) {
        this.rules.push(ruleDefinition.ruleFunction)
      }
    })
  }

  parseRequiredCheckbox() {
    if (this.formItem.byPassRequired === true) {
      return
    }

    const required =
      Object.hasOwnProperty.call(this.formItem.validation, 'required') &&
      this.formItem.validation.required === true

    if (this.formItem.type === 'checkbox' && required) {
      this.rules.push(
        v => v === true || 'u moet akkoord gaan, om verder te kunnen',
      )
    }
  }

  // NOTE: legacy function. This function allows you to directly add validation functions in your
  // formItem.validation.rules property.
  // This can only be used with static forms. E.g. you cannot serve a form over an API if it includes
  // functions.

  addCustomRules() {
    if (Object.hasOwnProperty.call(this.formItem.validation, 'rules')) {
      this.rules = this.formItem.validation.rules
    }
  }

  addToRules(rule) {
    this.rules.push(rule)
  }
}
