<template>
  <div>
    <v-switch
      v-model="localValue"
      :label="label"
    />
  </div>
</template>

<script>
import { mixinFormItemGeneric } from '../mixins/mixin-form-item-generic'

export default {
  mixins: [mixinFormItemGeneric],
  computed: {
    label() {
      return this.processReplacements(this.formItem.title)
    },
  },
  watch: {
    localValue(newValue) {
      this.$store.dispatch(this.formItem.action, {
        // todo: make generic
        formName: 'formConfigOpCharge',
        byPassRequired: newValue,
      })
    },
  },
  beforeMount() {
    this.$store.dispatch(this.formItem.action, {
      // todo: make generic
      formName: 'formConfigOpCharge',
      byPassRequired: this.formItem.value,
    })
  },
  methods: {
    handleActionButtonAction() {
      let argument = undefined
      if (this.formItem.options && this.formItem.options.actionArgument) {
        argument = this.formItem.options.actionArgument
      }
      this.$store.dispatch(this.formItem.action, argument)
    },
  },
}
</script>
