<template>
  <div
    v-if="$asf.debugging.showFormItemDebugger"
    class="debugWrapper"
  >
    <div class="logoWrapper">
      <v-icon
        large
        color="primary"
        class="mr-2 titleIcon"
      >
        mdi-spider-thread
      </v-icon>
      <h1 class="title text--primary">
        cowFramework | Debugger
      </h1>
      <p
        class="text--primary font-weight-light subtitle-1 subtitle"
        v-text="title"
      />
    </div>
    <v-text-field
      v-model.number="expandDepth"
      :rules="rules()"
      label="Expand depth"
      outlined
      type="number"
      class="textField"
    />
    <json-viewer
      :key="uniqueKey"
      :expand-depth="parseInt(expandDepth)"
      copyable
      :identifier="identifier"
      :value="formItem"
      class="jsonViewer"
    />
  </div>
</template>

<script>
export default {
  props: {
    formName: {
      type: String,
      required: true,
    },
    panelId: {
      type: Number,
      required: true,
    },
    formItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      expandDepth: 1,
      // this is to fix json-viewer for now
      keyIndex: 1,
    }
  },
  computed: {
    title() {
      return `form: <strong>${this.formName}</strong> panel: <strong>${this.panelId}</strong> field: <strong>${this.formItem.id}</strong>`
    },
    identifier() {
      return `${this.formName}_${this.panelId})_${this.formItem.id})`
    },
    uniqueKey() {
      return `${this.identifier}-${this.keyIndex}`
    },
  },
  watch: {
    // this is to fix json-viewer for now
    expandDepth() {
      this.keyIndex++
    },
  },
  methods: {
    rules() {
      return [value => value >= 0, value => value <= 10]
    },
  },
}
</script>

<style lang="scss" scoped>
$debuggerBorder: rgba(12, 33, 58, 0.18);
$debuggerBackground: rgba(12, 33, 58, 0.09);

.debugWrapper {
  overflow: auto;
  position: relative;
  min-height: 130px;
  height: inherit;
  background: $debuggerBackground;
  border: 3px solid $debuggerBorder;
  border-radius: 7px;
  -webkit-box-shadow: 0 1px 13px -6px #000000;
  box-shadow: 0 1px 13px -6px #000000;
}

.logoWrapper {
  position: absolute;
  width: 600px;
}

.titleIcon {
  position: absolute;
  top: 16px;
  left: 16px;
}

.title {
  position: absolute;
  top: 12px;
  left: 66px;
}

.subtitle {
  position: absolute;
  top: 41px;
  left: 66px;
}

.textField {
  z-index: 1;
  position: absolute;
  top: 12px;
  right: 12px;
  width: 100px;
}

.jsonViewer {
  height: auto;
  margin-bottom: 62px;
  left: 0;
  top: 68px;
  background: transparent !important;
  width: calc(100% - 140px);
}
</style>
