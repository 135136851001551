// This function is to replace optional chaining

// optional chaining example:
// this.formItem?.options?.itemValue
// can be replaced by:
// nestedProperty(this.formItem, "options", "itemValue");

module.exports.nestedProperty = function(baseObject) {
  for (let argument = 1; argument < arguments.length; argument++) {
    const property = arguments[argument]
    if (
      baseObject !== undefined &&
      baseObject !== null &&
      Object.hasOwnProperty.call(baseObject, property)
    ) {
      baseObject = baseObject[property]
    } else {
      baseObject = undefined
    }
  }
  return baseObject
}
