import Vue from 'vue'
import Vuetify, {
  VApp,
  VAppBar,
  VAppBarNavIcon,
  VAppBarTitle,
  VAlert,
  VAutocomplete,
  VAvatar,
  VBadge,
  VBanner,
  VBottomNavigation,
  VBottomSheet,
  VBreadcrumbs,
  VBreadcrumbsItem,
  VBreadcrumbsDivider,
  VBtn,
  VBtnToggle,
  VCalendar,
  VCalendarCategory,
  VCalendarDaily,
  VCalendarWeekly,
  VCalendarMonthly,
  VCard,
  VCardTitle,
  VCardSubtitle,
  VCardActions,
  VCardText,
  VCarousel,
  VCarouselItem,
  VCheckbox,
  VSimpleCheckbox,
  VChip,
  VChipGroup,
  VColorPicker,
  VColorPickerSwatches,
  VColorPickerCanvas,
  VContent,
  VCombobox,
  VCounter,
  VData,
  VDataIterator,
  VDataFooter,
  VDataTable,
  VEditDialog,
  VTableOverflow,
  VDataTableHeader,
  VSimpleTable,
  VVirtualTable,
  VVirtualScroll,
  VDatePicker,
  VDatePickerTitle,
  VDatePickerHeader,
  VDatePickerDateTable,
  VDatePickerMonthTable,
  VDatePickerYears,
  VDialog,
  VDivider,
  VExpansionPanels,
  VExpansionPanel,
  VExpansionPanelHeader,
  VExpansionPanelContent,
  VFileInput,
  VFooter,
  VForm,
  VContainer,
  VCol,
  VRow,
  VSpacer,
  VLayout,
  VFlex,
  VHover,
  VIcon,
  VImg,
  VInput,
  VItem,
  VItemGroup,
  VLabel,
  VLazy,
  VListItemActionText,
  VListItemContent,
  VListItemTitle,
  VListItemSubtitle,
  VList,
  VListGroup,
  VListItem,
  VListItemAction,
  VListItemAvatar,
  VListItemIcon,
  VListItemGroup,
  VMain,
  VMenu,
  VMessages,
  VNavigationDrawer,
  VOverflowBtn,
  VOverlay,
  VPagination,
  VSheet,
  VParallax,
  VPicker,
  VProgressCircular,
  VProgressLinear,
  VRadioGroup,
  VRadio,
  VRangeSlider,
  VRating,
  VResponsive,
  VSelect,
  VSkeletonLoader,
  VSlider,
  VSlideGroup,
  VSlideItem,
  VSnackbar,
  VSparkline,
  VSpeedDial,
  VStepper,
  VStepperContent,
  VStepperStep,
  VStepperHeader,
  VStepperItems,
  VSubheader,
  VSwitch,
  VSystemBar,
  VTabs,
  VTab,
  VTabItem,
  VTabsItems,
  VTabsSlider,
  VTextarea,
  VTextField,
  VThemeProvider,
  VTimeline,
  VTimelineItem,
  VTimePicker,
  VTimePickerClock,
  VTimePickerTitle,
  VToolbar,
  VToolbarItems,
  VToolbarTitle,
  VTooltip,
  VTreeview,
  VTreeviewNode,
  VWindow,
  VWindowItem,
  VCarouselTransition,
  VCarouselReverseTransition,
  VTabTransition,
  VTabReverseTransition,
  VMenuTransition,
  VFabTransition,
  VDialogTransition,
  VDialogBottomTransition,
  VDialogTopTransition,
  VFadeTransition,
  VScaleTransition,
  VScrollXTransition,
  VScrollXReverseTransition,
  VScrollYTransition,
  VScrollYReverseTransition,
  VSlideXTransition,
  VSlideXReverseTransition,
  VSlideYTransition,
  VSlideYReverseTransition,
  VExpandTransition,
  VExpandXTransition,
} from 'vuetify/lib'

import {
  ClickOutside,
  Intersect,
  Mutate,
  Resize,
  Ripple,
  Scroll,
  Touch,
} from 'vuetify/lib/directives'

Vue.use(Vuetify, {
  components: {
    VApp,
    VAppBar,
    VAppBarNavIcon,
    VAppBarTitle,
    VAlert,
    VAutocomplete,
    VAvatar,
    VBadge,
    VBanner,
    VBottomNavigation,
    VBottomSheet,
    VBreadcrumbs,
    VBreadcrumbsItem,
    VBreadcrumbsDivider,
    VBtn,
    VBtnToggle,
    VCalendar,
    VCalendarCategory,
    VCalendarDaily,
    VCalendarWeekly,
    VCalendarMonthly,
    VCard,
    VCardTitle,
    VCardSubtitle,
    VCardActions,
    VCardText,
    VCarousel,
    VCarouselItem,
    VCheckbox,
    VSimpleCheckbox,
    VChip,
    VChipGroup,
    VColorPicker,
    VColorPickerSwatches,
    VColorPickerCanvas,
    VContent,
    VCombobox,
    VCounter,
    VData,
    VDataIterator,
    VDataFooter,
    VDataTable,
    VEditDialog,
    VTableOverflow,
    VDataTableHeader,
    VSimpleTable,
    VVirtualTable,
    VVirtualScroll,
    VDatePicker,
    VDatePickerTitle,
    VDatePickerHeader,
    VDatePickerDateTable,
    VDatePickerMonthTable,
    VDatePickerYears,
    VDialog,
    VDivider,
    VExpansionPanels,
    VExpansionPanel,
    VExpansionPanelHeader,
    VExpansionPanelContent,
    VFileInput,
    VFooter,
    VForm,
    VContainer,
    VCol,
    VRow,
    VSpacer,
    VLayout,
    VFlex,
    VHover,
    VIcon,
    VImg,
    VInput,
    VItem,
    VItemGroup,
    VLabel,
    VLazy,
    VListItemActionText,
    VListItemContent,
    VListItemTitle,
    VListItemSubtitle,
    VList,
    VListGroup,
    VListItem,
    VListItemAction,
    VListItemAvatar,
    VListItemIcon,
    VListItemGroup,
    VMain,
    VMenu,
    VMessages,
    VNavigationDrawer,
    VOverflowBtn,
    VOverlay,
    VPagination,
    VSheet,
    VParallax,
    VPicker,
    VProgressCircular,
    VProgressLinear,
    VRadioGroup,
    VRadio,
    VRangeSlider,
    VRating,
    VResponsive,
    VSelect,
    VSkeletonLoader,
    VSlider,
    VSlideGroup,
    VSlideItem,
    VSnackbar,
    VSparkline,
    VSpeedDial,
    VStepper,
    VStepperContent,
    VStepperStep,
    VStepperHeader,
    VStepperItems,
    VSubheader,
    VSwitch,
    VSystemBar,
    VTabs,
    VTab,
    VTabItem,
    VTabsItems,
    VTabsSlider,
    VTextarea,
    VTextField,
    VThemeProvider,
    VTimeline,
    VTimelineItem,
    VTimePicker,
    VTimePickerClock,
    VTimePickerTitle,
    VToolbar,
    VToolbarItems,
    VToolbarTitle,
    VTooltip,
    VTreeview,
    VTreeviewNode,
    VWindow,
    VWindowItem,
    VCarouselTransition,
    VCarouselReverseTransition,
    VTabTransition,
    VTabReverseTransition,
    VMenuTransition,
    VFabTransition,
    VDialogTransition,
    VDialogBottomTransition,
    VDialogTopTransition,
    VFadeTransition,
    VScaleTransition,
    VScrollXTransition,
    VScrollXReverseTransition,
    VScrollYTransition,
    VScrollYReverseTransition,
    VSlideXTransition,
    VSlideXReverseTransition,
    VSlideYTransition,
    VSlideYReverseTransition,
    VExpandTransition,
    VExpandXTransition,
  },
  directives: {
    ClickOutside,
    Intersect,
    Mutate,
    Resize,
    Ripple,
    Scroll,
    Touch,
  },
})

const vuetifyWithOptions = options => {
  return new Vuetify(options)
}

export { vuetifyWithOptions }
