<template>
  <div>
    <p
      v-if="formItem.title"
      class="mt-5 mb-3 theme--light v-label"
    >
      {{ title }}
    </p>
    <v-radio-group
      v-model="localValue"
      :mandatory="required"
      :rules="radioRules"
      class="pa-2"
    >
      <v-radio
        v-for="item in localItems"
        v-show="item.show.value || true"
        :key="item[itemValue]"
        :label="`${processReplacements(item[itemText])}`"
        :value="item.id"
        :readonly="readOnly"
      />
    </v-radio-group>
  </div>
</template>

<script>
import { mixinFormItemGeneric } from '../mixins/mixin-form-item-generic'
import { nestedProperty } from '../../../stateful-forms'

export default {
  mixins: [mixinFormItemGeneric],
  computed: {
    radioRules() {
      const radioValueMustBe = nestedProperty(
        this.formItem,
        'validation',
        'radioValueMustBe',
      )

      let rules = []

      if (radioValueMustBe) {
        const valueMustBe = radioValueMustBe
        rules.push(this.localValue === valueMustBe || '')
      }

      return rules
    },
  },
}
</script>
