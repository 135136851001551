const hostName = document.location.origin

module.exports.consoleDevelopmentInformation = [
  '%cAccow Stateful Forms | development helper\n%c',
  'color: green; font-size: 16px; padding: 8px 0px',
  'color: green; font-size: 12px; padding: 6px 6px',
  'Below some links to local helper-pages that are only accessible during development\n\n',
  `Component-overview:  ${hostName}/development-overview\n`,
  `Manual:              ${hostName}/manual\n`,
  `Settings:            ${hostName}/project-settings\n`,
]
