<template>
  <v-text-field
    v-model="localValue"
    :rules="rules"
    :label="title"
    :clearable="clearable"
    outlined
    :type="'number'"
    style="width: 320px"
    :readonly="readOnly"
  />
</template>

<script>
import { mixinFormItemGeneric } from '../mixins/mixin-form-item-generic'

export default {
  mixins: [mixinFormItemGeneric],
}
</script>
