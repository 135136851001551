const manualSettings = {
  title: 'Manual > Settings',
  formName: 'manualSettings',
  allPanelsOpen: true,
  introText:
    'Todo: in this chapter the overview will contain all the possible examples of what you can do with the settings implementation of ASF',
  panels: [
    {
      id: 1,
      title: 'First example:',
      show: {
        value: true,
      },
      formItems: [],
    },
  ],
}

export { manualSettings }
