import AsfComponentOverview from '../views/AsfComponentOverview.vue'
import AsfSettingsPages from '../views/AsfSettingsPages.vue'
import AsfManualPages from '../views/AsfManualPages.vue'

const routeComponentOverview = {
  icon: 'mdi-file-document-edit-outline',
  title: 'Development overview',
  path: '/development-overview',
  name: 'DevelopmentOverview',
  component: AsfComponentOverview,
}

const routeManualPages = {
  icon: 'mdi-file-document-edit-outline',
  title: 'Manual',
  path: '/manual',
  name: 'Manual',
  component: AsfManualPages,
}

const routeSettings = {
  icon: 'mdi-cog-outline',
  title: 'Settings',
  path: '/project-settings',
  name: 'AdfSettingsPages',
  component: AsfSettingsPages,
}

const routeDivider = {
  divider: true,
  path: '/don-t-use-this-path', // not functional but required as input for vue router
}

const routes = []

export class ConfigurableRouter {
  constructor(
    router,
    options = {
      applicationRoutes: [],
      addComponentOverviewRoute: true,
      addSettingsRoute: true,
      addManualRoute: true,
    },
    store,
  ) {
    this.routeComponentOverview = routeComponentOverview
    this.routeManualPages = routeManualPages
    this.routeSettings = routeSettings
    this.routeDivider = routeDivider
    this.routes = routes
    this.router = router
    this.options = options
    this.addDefaultRoutes()
    // this.addApplicationRoutes();
    this.setupRouterMiddleware(store)
  }

  addDefaultRoutes() {
    // Show a divider if one of the conditions is true

    if (
      this.options.addComponentOverviewRoute ||
      this.options.addManualRoute ||
      this.options.addSettingsRoute
    ) {
      this.routes.push(this.routeDivider)
    }

    if (this.options.addComponentOverviewRoute) {
      this.routes.push(this.routeComponentOverview)
    }
    if (this.options.addManualRoute) {
      this.routes.push(this.routeManualPages)
    }
    if (this.options.addSettingsRoute) {
      this.routes.push(this.routeSettings)
    }
  }

  addApplicationRoutes() {
    this.routes = [...this.options.applicationRoutes, ...this.routes]
  }
  //
  // addSettingsRoutes(settingsRoutes) {
  //   this.routes = [...this.routes, ...settingsRoutes];
  // }

  setupRouterMiddleware(store) {
    this.router.beforeEach((to, from, next) => {
      store.commit('Project/setIsLoading', true)
      next()
    })

    this.router.afterEach(() => {
      setTimeout(() => {
        store.commit('Project/setIsLoading', false)
      }, 200) // timeout for demo purposes
    })
  }

  getRoutes() {
    return this.routes
  }
}
