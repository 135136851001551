import { developmentOverview } from './misc/development-overview'
import { manualConditionalShowProperty } from './manual/manual-conditional-show-property'
import { manualSharingDataBetweenComponents } from './manual/manual-sharing-data-between-components'
import { manualStringReplacement } from './manual/manual-string-replacement'
import { manualValidation } from './manual/manual-validation'
import { manualSettings } from './manual/manual-settings'
import { manualValidationMultiSelectObjects } from './manual/manual-validation-multi-select-objects'

const packageForms = {
  developmentOverview,
  manualConditionalShowProperty,
  manualSharingDataBetweenComponents,
  manualStringReplacement,
  manualValidation,
  manualValidationMultiSelectObjects,
  manualSettings,
}

export { packageForms }
