<template>
  <v-checkbox
    v-model="localValue"
    class="mt-0 ml-1"
    :label="title"
    :rules="rules"
    :readonly="readOnly"
    style="display: none"
  >
    <template #label>
      Dit is een onzichtbare checkbox
    </template>
  </v-checkbox>
</template>

<script>
import { mixinFormItemGeneric } from '../mixins/mixin-form-item-generic'

export default {
  mixins: [mixinFormItemGeneric],
  data() {
    return {}
  },
}
</script>
