const manualValidationMultiSelectObjects = {
  title: 'Manual > Validation Multi Select (Objects)',
  formName: 'manualValidationMultiSelectObjects',
  allPanelsOpen: true,
  introText:
    'Todo: in this chapter the overview will contain all the possible validation examples',
  panels: [
    {
      id: 1,
      title: 'First example:',
      show: {
        value: true,
      },
      formItems: [],
    },
  ],
}

export { manualValidationMultiSelectObjects }
