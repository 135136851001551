<template>
  <div class="mt-3">
    <v-alert
      :color="formItem.color"
      dark
      :icon="formItem.icon"
      border="left"
      prominent
      :outlined="formItem.outlined"
    >
      <div v-text="title" />
    </v-alert>
  </div>
</template>

<script>
import { mixinFormItemGeneric } from '../mixins/mixin-form-item-generic'

export default {
  mixins: [mixinFormItemGeneric],
}
</script>
