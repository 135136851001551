<template>
  <v-container
    class="mb-6"
    fluid
  >
    <v-row justify="center">
      <v-sheet
        color="white"
        elevation="2"
        height="60px"
        width="100%"
        class="white text-subtitle-2"
        style="text-transform: none"
      >
        <v-card
          class="pt-4 d-flex align-start flex-row"
          width="100%"
        >
          <v-card
            class="pl-4 d-flex align-start flex-column"
            flat
            height="100"
            style="display: inline-block !important"
            v-text="multiSelect ? label : selected"
          />
          <v-card
            v-if="multiSelect"
            class="d-flex align-end flex-column"
            flat
            width="50%"
            height="100"
          >
            <v-btn
              class="mr-3"
              outlined
              x-small
              @click.stop="collapseAll"
            >
              close
            </v-btn>
          </v-card>
        </v-card>
      </v-sheet>

      <v-expansion-panels
        v-model="openPanel"
        accordion
      >
        <v-expansion-panel
          v-for="(menu, index) in formItem.menus"
          :key="index"
          :readonly="readOnly"
        >
          <v-expansion-panel-header
            class="text-subtitle-2"
            style="text-transform: none"
            :class="menuIsActive(menu) ? 'menuPanelActive' : ''"
            v-text="
              multiSelect
                ? `${showSelectedValue(menu)}`
                : `${showSingleSelectedValue(menu)}`
            "
          />
          <v-expansion-panel-content>
            <v-btn
              v-for="(menuItem, indexItem) in menu.items"
              :key="indexItem"
              width="100%"
              class="mx-auto text-center text--primary"
              text
              :outlined="buttonIsActive(menu, menuItem)"
              style="text-transform: none"
              @click.stop="select(menu, menuItem)"
              v-text="menuItem.title"
            />
            <slot :menu="menu" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <v-text-field
      v-model="localValue.title"
      :rules="rules"
      hidden
    />
  </v-container>
</template>

<script>
import { mixinFormItemGeneric } from '../mixins/mixin-form-item-generic'

export default {
  mixins: [mixinFormItemGeneric],
  props: {
    isZoomSelector: {
      type: Boolean,
      default: false,
    },
    keepOpen: {
      type: Boolean,
      default: false,
    },
    multiSelect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      openPanel: -1,
      selected: 'Choose your option:',
    }
  },
  beforeMount() {
    if (this.formItem.value === null) {
      this.formItem.value = {}
    }
    this.initialize()
  },
  methods: {
    showSelectedValue(menu) {
      return menu.items.find(item => item.id === menu.value).title
    },
    showSingleSelectedValue(menu) {
      if (
        Object.hasOwnProperty.call(this.formItem.value, 'menuId') &&
        menu.id === this.formItem.value.menuId &&
        Object.hasOwnProperty.call(this.formItem.value, 'title')
      ) {
        return `${menu.title}: ${this.formItem.value.title}`
      } else if (
        Object.hasOwnProperty.call(this.formItem.value, 'selected') &&
        Object.hasOwnProperty.call(this.formItem.value.selected, 'title') &&
        Object.hasOwnProperty.call(this.formItem.value.selected, 'menuId') &&
        menu.id === this.formItem.value.selected.menuId
      ) {
        return `${menu.title}: ${this.formItem.value.selected.title}`
      } else {
        return `${menu.title}`
      }
    },
    initialize() {
      if (this.isZoomSelector) {
        this.initializeAsZoomSelector()
      } else {
        this.initializeDefault()
      }
    },
    initializeDefault() {
      if (Object.hasOwnProperty.call(this.formItem.value, 'menuItemId')) {
        this.selected = `Your choice: ${this.formItem.value.menuId}.${this.formItem.value.menuItemId}: ${this.formItem.value.title}`
      } else {
        this.selected = this.title
      }
    },
    initializeAsZoomSelector() {
      if (Object.hasOwnProperty.call(this.formItem.value, 'selected')) {
        this.selected = `Your choice: ${this.formItem.value.selected.menuId}.${this.formItem.value.selected.menuItemId}: ${this.formItem.value.selected.title}`
      } else {
        this.selected = this.title
      }
    },
    select(menu, menuItem) {
      if (!this.multiSelect) {
        this.handleSingleSelect(menu, menuItem)
      } else {
        this.handleMultiSelect(menu, menuItem)
      }
    },
    handleSingleSelect(menu, menuItem) {
      this.selected = `Your choice: ${menuItem.title}`
      if (
        this.$store.state.AsfForms.settingsVariables.showIdsInPanelMenuSelectors
      ) {
        this.selected = `Your choice: ${menuItem.title} (menu.id = ${menu.id} menuItem.id = ${menuItem.id})`
      }

      const data = {
        menuId: menu.id,
        menuItemId: menuItem.id,
        title: menuItem.title,
      }

      this.$emit('change', data)

      if (!this.keepOpen) {
        this.closePanels()
      }

      if (this.keepOpen && menuItem.id === 1) {
        this.closePanels()
      }
    },
    handleMultiSelect(menu, menuItem) {
      menu.value = menuItem.id

      if (menuItem.id < 3) {
        this.closePanels()
      }
      this.$emit('changeValue', this.menus)
    },
    selectNone() {
      this.selected = this.title
      this.$emit('changeValue', null)
      this.closePanels()
    },
    closePanels() {
      setTimeout(() => {
        this.openPanel = -1
      }, 220)
    },
    collapseAll() {
      this.closePanels()
    },
    menuIsActive(menu) {
      return (
        this.menuIsActiveZoomSelector(menu) || this.menuIsActiveDefault(menu)
      )
    },
    menuIsActiveZoomSelector(menu) {
      return (
        this.isZoomSelector &&
        Object.hasOwnProperty.call(this.formItem.value, 'selected') &&
        this.formItem.value.selected.menuId === menu.id
      )
    },
    menuIsActiveDefault(menu) {
      return !this.isZoomSelector && this.formItem.value.menuId === menu.id
    },
    buttonIsActive(menu, menuItem) {
      return (
        this.buttonIsActiveZoomSelector(menu, menuItem) ||
        this.buttonIsActiveDefault(menu, menuItem)
      )
    },
    buttonIsActiveZoomSelector(menu, menuItem) {
      return (
        this.isZoomSelector &&
        Object.hasOwnProperty.call(this.formItem.value, 'selected') &&
        this.formItem.value.selected.menuId === menu.id &&
        this.formItem.value.selected.menuItemId === menuItem.id
      )
    },
    buttonIsActiveDefault(menu, menuItem) {
      return (
        !this.isZoomSelector &&
        this.formItem.value.menuId === menu.id &&
        this.formItem.value.menuItemId === menuItem.id
      )
    },
  },
}
</script>

<style lang="scss" scoped>
$activeColor: rgb(213, 213, 213);

.menuPanelActive {
  background: $activeColor;
}

.v-expansion-panel.v-expansion-panel--active.v-item--active {
  background: $activeColor;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
</style>
