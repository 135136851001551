<template>
  <v-container class="ml-0 evt-file-input__container">
    <v-file-input
      :ref="`file-input-${formItem.title}`"
      v-model="file"
      class="pt-0 mt-0 evt-file-input__input"
      show-size
      type="file"
      :disabled="maximumNumberOfFileUploadAttemptsExceeded"
      :accept="acceptedFileTypes"
      :rules="rules"
      :clearable="true"
      :label="processReplacements(formItem.title)"
      prepend-icon="mdi-file"
      @click:clear="handleClear"
      @change="handleFileInput"
    />
    <v-alert
      v-show="showSuccessMessage"
      outlined
      type="success"
    >
      {{ successMessage }}
    </v-alert>
    <v-alert
      v-show="showErrorMessage"
      outlined
      type="error"
    >
      {{ errorMessage }}
    </v-alert>
  </v-container>
</template>

<script>
import { mixinFormItemGeneric } from '../../asf/stateful-forms'

export default {
  mixins: [mixinFormItemGeneric],
  data() {
    return {
      // this will hold the array of uploadedFiles and their context
      uploadedFiles: [],
      showErrorMessage: false,
      errorMessage: 'Er is een fout opgetreden',
      showSuccessMessage: false,
      successMessage: 'Er is iets goed gegaan',

      // the file attribute used by the v-file-input as v-model for the current file
      file: null,
      // the fileIndex
      fileIndex: 1,
      maximumUploadAttempts: 3,
    }
  },
  computed: {
    // If you have multiple fileUploads in one form, we have to distinguish
    // them, you can do that with the formItem.options attribute "doctype"
    // if you don't set this one the upload won't validate
    doctype() {
      const allowedDoctypes = ['vehicle', 'parkingspot']
      return this.formItem?.options?.doctype &&
        allowedDoctypes.includes(this.formItem.options.doctype)
        ? this.formItem.options.doctype
        : console.error('No (valid) doctype specified!')       // eslint-disable-line no-console
    },

    maximumNumberOfFileUploadAttemptsExceeded() {
      return this.fileIndex > this.maximumUploadAttempts
    },

    // The store action for uploading a file can be set in the
    // the formItem.options attribute "storeAction"

    storeAction() {
      return this.formItem?.options?.storeAction || 'Project/handleFileUpload'
    },

    // Error massage if maximum upload-capacity has been reached

    errorMaximumNumberOfFileUploadAttemptsExceeded() {
      return `Maximum number of upload attempts of ${
        this.maximumUploadAttempts
      } has been reached${this.noFilesAllowed > 1 ? 's' : ''} reached`
    },

    // Accepted file types

    acceptedFileTypes() {
      return (
        this.formItem?.options?.acceptedFileTypes ||
        'image/jpg, image/jpeg, image/png, .pdf'
      )
    },

    acceptedFileTypesFileExtensionsOnly() {
      return (
        this.formItem?.options?.acceptedFileTypesFileExtensionsOnly || [
          'jpg',
          'jpeg',
          'png',
          'pdf',
        ]
      )
    },

    notAnAcceptedFileTypeErrorMessage() {
      let message =
        'Dit bestandsformaat is niet toegestaan. De toegestane formaten zijn:   '
      for (const index in this.acceptedFileTypesFileExtensionsOnly) {
        message += `.${this.acceptedFileTypesFileExtensionsOnly[index]} `
      }
      return message
    },
  },
  watch: {
    // If uploadedFiles changes we have to update our localValue
    // to inform the formItem to do an update-call

    uploadedFiles() {
      const lastUploadedFile =
        this.uploadedFiles[this.uploadedFiles.length - 1]
      this.localValue = []
      if (this.file !== null) {
        this.localValue = [this.mapUploadedFileObject(lastUploadedFile)]
      }
    },

    maximumNumberOfFileUploadAttemptsExceeded() {
      this.triggerErrorMessage(
        this.errorMaximumNumberOfFileUploadAttemptsExceeded,
      )
    },
  },
  methods: {
    mapUploadedFileObject(uploadedFileObject) {
      // Benodigde attributen
      // - fileExtensie (File.type) "ext"
      // - datum Date.now() timestamp (kan in de mapper) !! Heel belangrijk "date"

      return {
        name: uploadedFileObject.name,
        id: uploadedFileObject.index,
        ext: uploadedFileObject.ext,
        date: Date.now(),
        uuid: uploadedFileObject.uuid,
      }
    },
    async handleFileInput(file) {
      // todo:: move this to a better place?
      // ### PATCH: prevent users from uploading invalid filenames ###
      // When the input changes this method is triggered, so
      if(file === null) {
        return
      }

      const oneLineAllowedCharactersOnly =
        /^([a-zA-Z0-9'\-,._@+!?&:;()#/= ])+$/
      if (!oneLineAllowedCharactersOnly.test(file.name)) {
        this.fireErrorMessage('Bestandsnaam bevat ongeldige tekens.')

        setTimeout(
          this.$refs[`file-input-${this.formItem.title}`].clearableCallback,
          4000,
        )

        return
      }
      // ### END PATCH ###

      let type = file.type
      if (type.includes('/')) {
        type = type.split('/').reverse()[0]
      }

      if (type.includes('.')) {
        type = type.split('.').reverse()[0]
      }

      if (!this.acceptedFileTypesFileExtensionsOnly.includes(type)) {
        this.fireErrorMessage(this.notAnAcceptedFileTypeErrorMessage, 6000)
        return
      }

      if (this.fileIndex <= this.maximumUploadAttempts) {
        const fileObject = { file }
        // eslint-disable-next-line no-console
        console.log('file', file)

        fileObject.name = file.name
        fileObject.doctype = this.doctype
        fileObject.ext = type
        fileObject.index = this.fileIndex

        await this.$store
          .dispatch(this.storeAction, fileObject)
          .then((response) => {
            fileObject.uuid = response.fileUuid
            this.uploadedFiles.push(fileObject)
            this.fireSuccessMessage(
              `Uploaden van bestand ${file.name} is gelukt`,
            )
          })
          .catch((error) => {
            if (Object.hasOwnProperty.call(error, 'message')) {
              this.fireErrorMessage(error.message, 6000)
            } else {
              this.fireErrorMessage(
                `Uploaden van bestand ${file.name} is helaas niet gelukt`,
                6000,
              )
            }
            // set v-model to null to make sure validating required will fail
            setTimeout(() => {
              this.file = null
            }, 3000)
          })
          .finally(() => {
            this.fileIndex++
          })
      }
    },
    handleClear() {
      this.file = null
      this.uploadedFiles = []
    },
    fireErrorMessage(message, timeOut = 4000) {
      this.errorMessage = message
      this.triggerErrorMessage(timeOut)
    },
    triggerErrorMessage(timeOut) {
      this.showErrorMessage = true
      setTimeout(() => {
        this.showErrorMessage = false
      }, timeOut)
    },
    fireSuccessMessage(message, timeOut = 4000) {
      this.successMessage = message
      this.triggerSuccessMessage(timeOut)
    },
    triggerSuccessMessage(timeOut) {
      this.showSuccessMessage = true
      setTimeout(() => {
        this.showSuccessMessage = false
      }, timeOut)
    },
  },
}
</script>

<style lang="scss">
.evt-file-input {
  &__input button.v-icon.v-icon {
    font-size: 36px;
  }
}

.v-file-input input[type=file] {
  opacity: 0.01 !important;
  max-width: 1px !important;
  width: 1px !important;
  height: 0 !important;
  background: rgba(255,255,255,0);
  border: none;
}
</style>
