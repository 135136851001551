<template>
  <v-container>
    <div v-show="!readOnly">
      <v-btn
        v-show="selectorStateAll"
        outlined
        x-small
        @click.stop="selectAll"
      >
        select all
      </v-btn>
      <v-btn
        v-show="!selectorStateAll"
        outlined
        x-small
        @click.stop="selectNone"
      >
        select none
      </v-btn>
    </div>
    <v-card
      flat
      class="d-flex flex-wrap py-3"
    >
      <v-checkbox
        v-for="item in localItems"
        :key="item.id"
        v-model="item.value"
        class="ml-2 mb-1"
        :label="processReplacements(item.title)"
        :readonly="readOnly"
        hide-details
        style="width: 300px"
        @click="updateParentData"
      >
        <template #label>
          <div v-text="processReplacements(item.title)" />
        </template>
      </v-checkbox>
    </v-card>
    <v-text-field
      v-model="numberOfSelected"
      :rules="rules"
      hidden
    />
  </v-container>
</template>

<script>
import { mixinFormItemGeneric } from '../mixins/mixin-form-item-generic'

export default {
  mixins: [mixinFormItemGeneric],
  data() {
    return {
      selectorStateAll: true,
    }
  },
  computed: {
    numberOfSelected() {
      return this.localValue.filter((item) => item.value === true).length
    },
  },
  beforeMount() {
    this.initialize()
  },
  methods: {
    initialize() {
      this.localItems.forEach((item) => {
        item.value = false
      })
      this.$emit('change', this.localItems)
    },
    updateParentData() {
      this.$emit('change', this.localItems)
    },
    selectAll() {
      this.localItems.forEach((item) => {
        item.value = true
      })
      this.$forceUpdate()
      this.$emit('change', this.localItems)
      this.selectorStateAll = false
    },
    selectNone() {
      this.localItems.forEach((item) => {
        item.value = false
      })
      this.$forceUpdate()
      this.$emit('change', this.localItems)
      this.selectorStateAll = true
    },
  },
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
