import { ConfigurableRouter } from './dependencies/ConfigurableRouter'
import { MergeModelWithFormConfig } from './model-logic/merge-model-with-form-config'
import { MapFormToRecordData } from './store/logic/forms/map-form-to-record-data'
import { nestedProperty } from './helpers/nested-property'
import { mapArrayOfStrings } from './store/logic/helpers'
import { packageValidationRuleDefinitions } from './model-logic/validation/package-validation-rule-definitions'
import { registerCoreFormItems } from './register-components'
import { mixinFormItemGeneric } from './components/form-builder/mixins/mixin-form-item-generic'
import { asfForms } from './store/asf-forms'
import { asfMessages } from './store/asf-messages'
import { vuetifyWithOptions } from './dependencies/Vuetify'
import JsonViewer from 'vue-json-viewer'
import 'vue-json-viewer/style.css'
import { consoleDevelopmentInformation } from './helpers/console-development-information'
require('./register-components')
const deepMerge = require('deepmerge')

const StatefulForms = {
  install: (Vue, store, options) => {
    // Global object

    Vue.prototype.$asf = {}

    // Some options should only be available in development

    const notInProduction = process.env.NODE_ENV !== 'production'

    //########################################################
    // Setup ConfigurableRouter class
    //########################################################

    if (
      Object.hasOwnProperty.call(options, 'router') &&
      options.router !== null &&
      options.router !== undefined
    ) {
      const defaultRouterOptions = {
        addComponentOverviewRoute: true,
        addSettingsRoute: true,
        addManualRoute: true,
      }

      const routerOptions = deepMerge(
        defaultRouterOptions,
        options.routerOptions || {},
      )

      // Omit Component overview and manual in production

      routerOptions.addComponentOverviewRoute =
        routerOptions.addManualRoute && notInProduction
      routerOptions.addManualRoute =
        routerOptions.addManualRoute && notInProduction

      const configurableRouter = new ConfigurableRouter(
        options.router,
        routerOptions,
        store,
      )

      const routes = configurableRouter.getRoutes()

      routes.forEach((route) => {
        options.router.addRoute(route)
      })

      // make the array of routes available for the application

      Vue.prototype.$asf.routes = configurableRouter.getRoutes()
    }

    //########################################################
    // Setup Vuetify
    //########################################################

    const defaultVuetifyOptions = {
      theme: {
        options: {
          customProperties: false,
        },
        themes: {
          light: {
            primary: '#0c213a',
            secondary: '#0c213a',
            accent: '#82B1FF',
            error: '#FF5252',
            info: '#2196F3',
            success: '#4CAF50',
            warning: '#FFC107',
          },
        },
      },
    }

    const vuetifyOptions = deepMerge(
      defaultVuetifyOptions,
      options.vuetifyOptions || {},
    )

    //########################################################
    // Setup store
    //########################################################

    store.registerModule('AsfForms', asfForms)
    store.registerModule('AsfMessages', asfMessages)

    // set register core form-items

    store.commit('AsfForms/setRegisterCoreFormItems', registerCoreFormItems)

    // set register custom form-items

    store.commit(
      'AsfForms/setRegisterCustomFormItems',
      options.registerCustomFormItems || [],
    )

    // add application forms to store

    store.commit(
      'AsfForms/addApplicationForms',
      options.applicationForms || {},
    )

    store.commit(
      'AsfForms/setCustomRenamables',
      options.customRenamables || {},
    )

    // set errorMessages

    const customErrorMessages = options.customErrorMessages || undefined

    // set package ruleDefinitions

    store.commit(
      'AsfForms/setPackageValidationRuleDefinitions',
      packageValidationRuleDefinitions(customErrorMessages),
    )

    // add customValidationRuleDefinitions to store

    store.commit(
      'AsfForms/setCustomValidationRuleDefinitions',
      options.customValidationRuleDefinitions(customErrorMessages) || [],
    )

    //########################################################
    // Setup debugging
    //########################################################

    const defaultDebuggingOptions = {
      showDebugBar: false,
      showMutationSnackbar: false,
      showPanelAndFormIds: false,
      showPanelDebugger: false,
      showFormItemDebugger: false,
      showPanelDebugModal: true,
    }

    const debuggingOptions = deepMerge(
      defaultDebuggingOptions,
      options.debugging || {},
    )

    Vue.prototype.$asf.debugging = {
      showDebugBar: debuggingOptions.showDebugBar && notInProduction,
      showMutationSnackbar:
        debuggingOptions.showMutationSnackbar && notInProduction,
      showPanelAndFormIds:
        debuggingOptions.showPanelAndFormIds && notInProduction,
      showPanelDebugger: debuggingOptions.showPanelDebugger && notInProduction,
      showFormItemDebugger:
        debuggingOptions.showFormItemDebugger && notInProduction,
      showPanelDebugModal:
        debuggingOptions.showPanelDebugModal && notInProduction,
    }

    //########################################################
    // Setup error-messages
    //########################################################

    //########################################################
    // Setup vue mixin
    //########################################################

    Vue.mixin({
      vuetify: vuetifyWithOptions(vuetifyOptions),
    })

    //########################################################
    // Setup miscellaneous
    //########################################################

    Vue.use(JsonViewer)

    //########################################################
    // Setup console information for development
    //########################################################
    if (notInProduction) {
      // eslint-disable-next-line
      console.log(...consoleDevelopmentInformation)
    }
  },
}

export {
  StatefulForms,
  mixinFormItemGeneric,
  MergeModelWithFormConfig,
  MapFormToRecordData,
  nestedProperty,
  mapArrayOfStrings,
}
