export const handleChange = function() {
  this.initializeValueForExport()
  this.populateValueForExport()
  this.$emit('change', this.localItems)
  this.$emit('setTempData', JSON.stringify(this.localItems))
  this.$forceUpdate()
}

export const selectFromNamingConventions = function() {
  this.localItems.forEach(item => {
    this.localSelectables.forEach(selectable => {
      if (item.name.includes(selectable.namingConvention)) {
        item[selectable.columnName] = true
      }
    })
  })
  this.handleChange()
}

export const selectAll = function() {
  this.localItems.forEach(item => {
    this.localSelectables.forEach(selectable => {
      item[selectable.columnName] = true
    })
  })
  this.handleChange()
}

export const selectAllFromColumn = function(event, columnName) {
  if (this.multiSelectedColumns.includes(columnName)) {
    this.localItems.forEach(item => {
      item[columnName] = true
    })
    this.handleChange()
  } else {
    this.localItems.forEach(item => {
      item[columnName] = false
    })
    this.handleChange()
  }
}

export const selectNone = function() {
  this.localItems.forEach(item => {
    this.localSelectables.forEach(selectable => {
      item[selectable.columnName] = false
    })
  })
  this.multiSelectedColumns = []
  this.handleChange()
}

////////////////////////////////////////////////////////////
// The export value (aggregate)                           //
////////////////////////////////////////////////////////////

export const initializeValueForExport = function() {
  this.value = {}
  this.localSelectables.forEach(s => {
    this.value[s.columnName] = []
  })
}

export const populateValueForExport = function() {
  this.localItems.forEach(item => {
    this.localSelectables.forEach(selectable => {
      if (item[selectable.columnName] === true) {
        this.value[selectable.columnName].push(item.name)
      }
    })
  })
}
