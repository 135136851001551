<template>
  <div class="mt-3 font-weight-regular body-2" v-html="title"/>
</template>

<script>
import { mixinFormItemGeneric } from '../mixins/mixin-form-item-generic'

export default {
  mixins: [mixinFormItemGeneric],
}
</script>
