<template>
  <div>
    <slot
      v-if="!showSpinner"
      transition="fade-transition"
    />
    <AsfLoadingSpinner
      v-if="showSpinner"
      style="z-index: 10000"
      transition="fade-transition"
    />
  </div>
</template>

<script>
export default {
  props: {
    showSpinner: {
      type: Boolean,
    },
  },
}
</script>
