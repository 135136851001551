<template>
  <asf-form-wrapper>
    <template #title>
      {{ formConfig.title }}
    </template>
    <template #introText>
      <div v-text="formConfig.introText" />
    </template>
    <slot name="notification" />
    <template #content>
      <asf-form-builder :form-config="formConfig" :can-export="canExport" />
    </template>
  </asf-form-wrapper>
</template>

<script>
export default {
  props: {
    formConfig: {
      type: Object,
      required: true,
    },
    canExport: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      text: [],
    }
  },
  computed: {
    panelsRenderedWithConditionalLogic() {
      return this.$store.getters['AsfForms/conditionalPanels'](
        this.formConfig.formName,
      )
    },
    generateExportButtonActive() {
      return this.canExport
    },
  }
}
</script>

<style lang="scss" scoped></style>
