module.exports.defaultErrorMessages = {
  REQUIRED: 'This field is required',
  MINIMAL_LENGTH: 'the minimum number of characters is',
  MAXIMUM_LENGTH:
    'the maximum number of characters has been exceeded, maximum is ',
  MINIMAL_VALUE: 'the value needs to be at least ',
  MAXIMUM_VALUE: 'the value needs to be at most ',
  INVALID_INTEGER: 'Value is not an integer',
  INVALID_FLOAT:
    'Value is not a valid float or number, the maximum numbers of decimals allowed is: ',
  IS_DUTCH_POSTAL_CODE: 'this is not a valid Dutch Postal Code',
  VALID_EMAIL: 'this is not a valid email address',
  AGREED: 'must be checked',
  INVALID_DATA_TYPE: 'an invalid data type has been sent',
  VALID_PHONE_NUMBER: 'this is not a valid phone number',
  ALLOWED_CHARACTERS_ONLY: 'invalid characters have been entered',
  THE_FIELD: 'the field',
  NOT_AVAILABLE_IN_DATA_SET: 'is not present in the data supplied',
  CANNOT_BE_EMPTY: 'cannot be empty',
}
