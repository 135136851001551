<template>
  <div>
    <p>Shared value mapped to componentKey1: {{ getSharedVariable("componentKey1") }}</p>
    <p>Shared value mapped to componentKey2: {{ getSharedVariable("componentKey2") }}</p>
  </div>
</template>

<script>
import { mixinFormItemGeneric } from '../mixins/mixin-form-item-generic'

export default {
  mixins: [mixinFormItemGeneric],
}
</script>
