<template>
  <v-text-field
    v-model="localValue"
    class="mt-3"
    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
    :type="showPassword ? 'text' : 'password'"
    :rules="rules"
    :counter="counter"
    :label="title"
    :required="required"
    :clearable="clearable"
    :readonly="readOnly"
    outlined
    @click:append="showPassword = !showPassword"
  />
</template>

<script>
import { mixinFormItemGeneric } from '../mixins/mixin-form-item-generic'

export default {
  mixins: [mixinFormItemGeneric],
  data() {
    return {
      showPassword: false,
    }
  },
}
</script>
