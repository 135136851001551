<template>
  <v-container
    class="spinnerWrapper"
    fill-height
    grid-list-md
    text-xs-center
  >
    <v-row justify="center">
      <grid-loader
        :color="spinnerColor"
        class="text--primary"
      />
    </v-row>
  </v-container>
</template>

<script>
import GridLoader from 'vue-spinner/src/GridLoader.vue'

export default {
  components: { GridLoader },
  data() {
    return {
      text: [],
    }
  },
  computed: {
    spinnerColor() {
      return this.$vuetify.theme.themes.light.primary
    },
  },
}
</script>

<style lang="scss" scoped>
.spinnerWrapper {
  max-width: 100vw;
}
</style>
