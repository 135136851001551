<template>
  <asf-matrix-select-core
    :key="formKey"
    class="mb-4"
    :form-name="formName"
    :name="formItem.name"
    :items="localItems"
    :temp-data="formItem.tempData"
    :meta-data="formItem.metaData"
    :fetch-items="formItem.fetchItems"
    :item-name="formItem.itemName"
    :selectables="formItem.selectables"
    :naming-conventions="useNamingConventions()"
    :add-text-filter="addTextFilter()"
    :label="processReplacements(formItem.title)"
    :read-only="readOnly"
    @change="updateFormItemData"
    @setTempData="setFormItemTempData"
    @setMetaData="setFormItemMetaData"
  />
</template>

<script>
import { mixinFormItemGeneric } from '../mixins/mixin-form-item-generic'

export default {
  mixins: [mixinFormItemGeneric],
  props: {
    formKey: {
      type: String,
      required: true,
    },
    formName: {
      type: String,
      required: true,
    },
  },
  computed: {
    localItems() {
      if (Object.hasOwnProperty.call(this.formItem, 'items')) {
        return this.formItem.items
      }
      let localItems

      if (
        Object.hasOwnProperty.call(this.formItem, 'items') &&
        this.formItem.items !== undefined &&
        this.formItem.items.length > 0
      ) {
        localItems = [...this.items]
      } else {
        localItems = this.$store.getters[this.formItem.fetchItems.getter]
      }

      return localItems
    },
  },
  methods: {
    updateFormItemData(value) {
      this.$emit('change', value)
    },
    setFormItemTempData(value) {
      this.$emit('setTempData', value)
    },
    setFormItemMetaData(value) {
      this.$emit('setMetaData', value)
    },
    useNamingConventions() {
      if (
        Object.hasOwnProperty.call(this.formItem, 'options') &&
        Object.hasOwnProperty.call(
          this.formItem.options,
          'useNamingConventions',
        )
      ) {
        return this.formItem.options.useNamingConventions
      }
      return false
    },
    addTextFilter() {
      if (
        Object.hasOwnProperty.call(this.formItem, 'options') &&
        Object.hasOwnProperty.call(this.formItem.options, 'addTextFilter')
      ) {
        return this.formItem.options.addTextFilter
      }
      return false
    },
  },
}
</script>
