<template>
  <v-row
    no-gutters
    class="ml-12 mr-12 mt-4 asf-form-wrapper"
  >
    <v-col>
      <v-row>
        <v-card
          class="pt-10 pl-6 pb-6 text-h4 primary--text"
          elevation="0"
        >
          <slot name="title" />
        </v-card>
      </v-row>
      <v-row>
        <v-card
          class="pt-6 pl-6 pb-6 primary--text"
          elevation="0"
          style="width: 100%"
        >
          <slot name="introText" />
        </v-card>
      </v-row>
      <v-row>
        <slot />
        <slot name="content" />
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      text: [],
    }
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
}
</script>

<style lang="scss">
.asf-form-wrapper {
  .row {
    display: block;
  }
}
</style>
