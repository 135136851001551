<template>
  <v-dialog
    v-if="$asf.debugging.showPanelDebugModal"
    v-model="dialog"
    scrollable
    max-width="90vw"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        color="primary"
        style="width: 36px;"
        icon
        dark
        v-bind="attrs"
        v-on="on"
      >
        <v-icon color="primary">
          mdi-spider-thread
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <v-btn
          color="primary"
          class="topClose"
          icon
          @click="closeDialog"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
        <div class="logoWrapper">
          <v-icon
            large
            color="primary"
            class="mr-2 titleIcon"
          >
            mdi-spider-thread
          </v-icon>
          <h1 class="title text--primary">
            cowFramework | Debugger
          </h1>
          <p
            class="text--primary font-weight-light subtitle-1 subtitle"
            v-text="title"
          />
        </div>
        <!--        <span class="text-h5">Debugger</span>-->
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-text-field
            v-model.number="expandDepth"
            :rules="rules()"
            label="Expand depth"
            outlined
            type="number"
            class="textField"
          />
          <json-viewer
            :key="uniqueKey"
            :expand-depth="parseInt(expandDepth)"
            copyable
            :identifier="identifier"
            :value="showData"
            class="jsonViewer"
          />
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          class="bottomClose"
          text
          @click="closeDialog"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    formName: {
      type: String,
      required: true,
    },
    panelId: {
      type: Number,
      required: true,
    },
    showData: {
      type: Object,
      required: true,
    },
    defaultExpandDepth: {
      type: Number,
      default: 4,
    },
  },
  data() {
    return {
      dialog: false,
      expandDepth: 1,
      // this is to fix json-viewer for now
      keyIndex: 1,
    }
  },
  computed: {
    title() {
      return `form: <strong>${this.formName}</strong> panel: <strong>${this.panelId}</strong>`
    },
    identifier() {
      return `${this.formName}_${this.panelId}`
    },
    uniqueKey() {
      return `${this.identifier}-${this.keyIndex}`
    },
  },
  watch: {
    // this is to fix json-viewer for now
    expandDepth() {
      this.keyIndex++
    },
  },
  beforeMount() {
    this.expandDepth = this.defaultExpandDepth
  },
  mounted() {},
  methods: {
    rules() {
      return [value => value >= 0, value => value <= 10]
    },
    closeDialog() {
      this.dialog = false
      this.$forceUpdate()
    },
  },
}
</script>

<style lang="scss" scoped>
$debuggerBorder: rgba(12, 33, 58, 0.18);
$debuggerBackground: rgba(12, 33, 58, 0.09);

.debugWrapper {
  overflow: auto;
  position: relative;
  min-height: 130px;
  height: inherit;
  background: $debuggerBackground;
  border: 3px solid $debuggerBorder;
  border-radius: 7px;
  -webkit-box-shadow: 0 1px 13px -6px #000000;
  box-shadow: 0 1px 13px -6px #000000;
}

.logoWrapper {
  position: absolute;
  width: 600px;
  left: 6px;
  top: 6px;
}

.titleIcon {
  position: absolute;
  top: 16px;
  left: 16px;
}

.title {
  position: absolute;
  top: 12px;
  left: 66px;
}

.subtitle {
  position: absolute;
  top: 41px;
  left: 66px;
}

.textField {
  z-index: 1;
  position: absolute;
  top: 92px;
  right: 12px;
  width: 100px;
}

.topClose {
  position: absolute;
  right: 12px;
  top: 12px;
}

.topClose {
  position: absolute;
  right: 12px;
  bottom: 12px;
}

.jsonViewer {
  height: auto;
  margin-bottom: 62px;
  left: 0;
  top: 68px;
  background: transparent !important;
  width: calc(100% - 140px);
}
</style>
