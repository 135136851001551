class LocalStorage {
  save(key, data) {
    localStorage[key] = JSON.stringify(data)
  }

  get(key) {
    if (Object.prototype.hasOwnProperty.call(localStorage, key)) {
      return JSON.parse(localStorage[key])
    }
    return false
  }
}

export const storage = new LocalStorage()
