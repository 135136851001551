<!--suppress ALL -->
<template>
  <div>
    <v-date-picker
      v-model="localValue"
      color="primary"
      scrollable
      show-week
    >
      <v-text-field
        v-model="localValue"
        :rules="rules"
        hidden
      />
    </v-date-picker>
  </div>
</template>

<script>
import { mixinFormItemGeneric } from '../mixins/mixin-form-item-generic'

export default {
  mixins: [mixinFormItemGeneric],
  mounted() {
    if (
      this.formItem.value === '' ||
      this.formItem.value === null ||
      this.formItem.value === undefined
    ) {
      const now = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10)
      this.formItem.value = now
    }
  },
}
</script>

<style lang="scss">
.v-date-picker-header {
  button.v-btn--round {
    background: lightgrey;
  }
}
</style>
