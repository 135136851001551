<template>
  <div
    v-if="$asf.debugging.showMutationSnackbar"
    class="text-center"
  >
    <v-snackbar
      v-model="snackbar"
      :vertical="vertical"
      :color="color"
    >
      {{ $store.getters["AsfMessages/getLastMutationMessage"] }}
      <template #action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click.stop="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data: () => ({
    vertical: true,
    color: 'primary',
  }),
  computed: {
    snackbar: {
      get() {
        return this.$store.state.AsfMessages.showMutationSnackbar
      },
      set(value) {
        this.$store.dispatch('AsfMessages/setShowMutationSnackbar', value)
      },
    },
  },
  watch: {},
  mounted() {},
  methods: {},
}
</script>

<style lang="scss" scoped></style>
