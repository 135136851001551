<template>
  <v-container fluid>
    <v-tabs
      :vertical="$vuetify.breakpoint.smAndDown"
      class="ml-4"
    >
      <v-tab
        v-for="form in forms"
        :key="form.id"
        @click.stop="currentForm = form.id"
      >
        {{ form.title }}
      </v-tab>
    </v-tabs>
    <asf-page-form-builder
      v-for="form in forms"
      v-show="currentForm === form.id"
      :key="form.id"
      :form-config="form.path"
    />
  </v-container>
</template>

<script>
export default {
  props: {
    defaultFormId: {
      type: Number,
      default: 1,
    },
    forms: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentForm: 1,
    }
  },
  watch: {
    currentForm(id) {
      this.setCurrentPath(id)
    },
  },
  beforeMount() {
    this.currentForm = this.defaultFormId
  },
  mounted() {
    this.setCurrentPath(this.currentForm)
  },
  methods: {
    setCurrentPath(id) {
      const form = this.forms.find(form => form.id === id)
      const formName = form.path.formName
      this.$store.dispatch('AsfForms/setCurrentPath', formName)
    },
  },
}
</script>
