<template>
  <div>
    Hi I'm a custom text-field

    <v-text-field
      v-model="localValue"
      class="mt-3"
      :rules="rules"
      :counter="counter"
      :label="title"
      :clearable="clearable"
      :readonly="readOnly"
      outlined
    />
  </div>
</template>

<script>
import { mixinFormItemGeneric } from '../../asf/stateful-forms'

export default {
  mixins: [mixinFormItemGeneric],
  data() {
    return {
      customRules: [
        (v) => !!v || 'De naam is verplicht',
        (v) => (v && v.length <= 10) || 'Name must be less than 10 characters',
      ],
    }
  },
}
</script>
