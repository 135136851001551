<template>
  <v-text-field
    v-model="localValue"
    class="mt-3"
    :rules="rules"
    :counter="counter"
    :label="title"
    :clearable="clearable"
    :readonly="readOnly"
    outlined
  />
</template>

<script>
import { mixinFormItemGeneric } from '../mixins/mixin-form-item-generic'

export default {
  mixins: [mixinFormItemGeneric],
}
</script>
