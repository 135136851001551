const manualConditionalShowProperty = {
  title: 'Manual > Conditional Show Property',
  formName: 'manualConditionalShowProperty',
  allPanelsOpen: true,
  introText:
    'In this example you can see how the <strong>show</strong> property works, switch on the debugger to see te settings: <br /> <strong>Settings > Debug > Show FormItem Data</strong>',
  panels: [
    {
      id: 1,
      title: 'Select one of the options:',
      show: {
        value: true,
      },
      formItems: [
        {
          id: 1,
          type: 'radio',
          title: 'Choose:',
          default: 1,
          value: 1,
          items: [
            {
              id: 1,
              title: 'Option 1',
              show: {
                value: true,
              },
            },
            {
              id: 2,
              title: 'Option 2:  Show Text-field',
              show: {
                value: true,
              },
            },
            {
              id: 3,
              title: 'Option 3:  Show Textveld and Checkbox',
              show: {
                value: true,
              },
            },
            {
              id: 4,
              title: 'Option 4:  Show Textveld and a Checkbox in panels below',
              show: {
                value: true,
              },
            },
          ],
          options: {
            formVariableKey: 'someKey',
          },
          show: {
            value: true,
          },
        },
        {
          id: 2,
          type: 'textField',
          title: 'It\'s show time',
          default: '',
          value: '',
          show: {
            value: false,
            multipleConditionsMode: 'allConditionsTrue',
            conditions: [
              {
                formItemPath: '1.1',
                operator: 'inArray',
                value: [2, 3],
              },
            ],
          },
          options: {
            clearable: true,
          },
          validation: {
            length: 11,
            required: true,
          },
        },
        {
          id: 3,
          type: 'checkbox',
          title: 'I love conditions',
          default: false,
          value: false,
          show: {
            value: false,
            multipleConditionsMode: 'allConditionsTrue',
            conditions: [
              {
                formItemPath: '1.1',
                operator: '===',
                value: 3,
              },
            ],
          },
        },
      ],
    },
    {
      id: 2,
      title: 'Text Field:',
      show: {
        value: false,
        multipleConditionsMode: 'oneOfTheConditionsTrue',
        conditions: [
          {
            formItemPath: '1.1',
            operator: '===',
            value: 4,
          },
        ],
      },
      formItems: [
        {
          id: 2,
          type: 'textField',
          title: 'Hello Kitty',
          default: '',
          value: '',
          options: {
            clearable: true,
          },
          validation: {
            length: 11,
            required: true,
          },
        },
      ],
    },
    {
      id: 3,
      title: 'Checkbox:',
      show: {
        value: false,
        multipleConditionsMode: 'oneOfTheConditionsTrue',
        conditions: [
          {
            formItemPath: '1.1',
            operator: '===',
            value: 4,
          },
        ],
      },
      formItems: [
        {
          id: 1,
          type: 'checkbox',
          title: 'Ik hou van condities',
          default: true,
          value: true,
        },
        {
          id: 2,
          type: 'checkbox',
          title: 'Ik wil nog een checkbox',
          default: false,
          value: false,
        },
        {
          id: 3,
          type: 'checkbox',
          title: 'Ik hou van nog meer checkboxes',
          default: true,
          value: true,
          show: {
            value: false,
            multipleConditionsMode: 'oneOfTheConditionsTrue',
            conditions: [
              {
                formItemPath: '3.2',
                operator: '===',
                value: true,
              },
            ],
          },
        },
      ],
    },
  ],
}

export { manualConditionalShowProperty }
