const manualSharingDataBetweenComponents = {
  title: 'Manual > Sharing Data Between Components',
  formName: 'manualSharingDataBetweenComponents',
  allPanelsOpen: true,
  introText:
    'In this example you can see how you can share data between formItems (and forms!)',
  panels: [
    {
      id: 1,
      title: 'In the example below you find two "textField" formItems, who share their data with a third field "sharedDataTester, checkout the formConfig to see how it is done":',
      formItems: [
        {
          id: 1,
          type: 'textField',
          title: 'Type a value below.',
          default: undefined,
          value: undefined,
          share: {
            to: "firstSharedValue"
          }
        },
        {
          id: 2,
          type: 'textField',
          title: 'Type a value below.',
          default: undefined,
          value: undefined,
          share: {
            to: "secondSharedValue"
          }
        },
        {
          id: 3,
          type: 'sharedDataTester',
          default: undefined,
          value: undefined,
          share: {
            from: {
              componentKey1: "firstSharedValue",
              componentKey2: "secondSharedValue"
            }
          }
        },
      ],
    },
  ],
}

export { manualSharingDataBetweenComponents }
