<template>
  <v-container fluid>
    <asf-page-form-builder
      :form-config="$store.state.AsfForms.forms.developmentOverview"
    />
  </v-container>
</template>

<script>
export default {}
</script>
