<template>
  <v-container>
    <v-text-field
      ref="inputMin"
      v-model="minValue"
      :label="label.titleMin"
      :rules="rulesMin()"
      outlined
      :readonly="readOnly"
      type="number"
      style="width: 260px"
    />
    <v-text-field
      ref="inputMax"
      v-model="maxValue"
      :label="label.titleMax"
      :rules="rulesMax()"
      outlined
      :readonly="readOnly"
      type="number"
      style="width: 260px"
    />
  </v-container>
</template>

<script>
import { mixinFormItemGeneric } from '../mixins/mixin-form-item-generic'

export default {
  mixins: [mixinFormItemGeneric],
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    label() {
      return this.formItem.label
    },
    minValue: {
      get: function() {
        return this.formItem.value.min
      },
      set: function(value) {
        this.$emit('change', {
          min: value,
          max: this.formItem.value.max,
        })
      },
    },
    maxValue: {
      get: function() {
        return this.formItem.value.max
      },
      set: function(value) {
        this.$emit('change', {
          min: this.formItem.value.min,
          max: value,
        })
      },
    },
  },
  watch: {
    localValue() {
      this.$nextTick(() => {
        this.validateBothInputs()
      })
    },
  },
  mounted() {
    this.validateBothInputs()
  },
  methods: {
    rulesMin() {
      const rules = []
      const valueIsFloat = v => {
        const regex = /^-?\d+(\.\d{1,2})?$/
        return String(v).match(regex)
          ? true
          : 'Value is not a valid float or number, max 2 decimals allowed'
      }
      const valueIsLessThanMax = v => {
        const value = parseInt(v)
        return value < parseInt(this.maxValue)
          ? true
          : 'Value must be less than the maximum value'
      }
      rules.push(valueIsFloat)
      rules.push(valueIsLessThanMax)

      return rules
    },
    rulesMax() {
      const rules = []
      const valueIsFloat = v => {
        const regex = /^-?\d+(\.\d{1,2})?$/
        return String(v).match(regex)
          ? true
          : 'Value is not a valid float or number max 2 decimals allowed'
      }

      const valueIsMoreThanMin = v => {
        const value = parseInt(v)
        return value > parseInt(this.minValue)
          ? true
          : 'Value must be more than the minimum value'
      }

      rules.push(valueIsFloat)
      rules.push(valueIsMoreThanMin)

      return rules
    },
    validateBothInputs() {
      this.$refs.inputMin.validate()
      this.$refs.inputMax.validate()
    },
  },
}
</script>

<style lang="scss" scoped></style>
