<!--suppress ALL -->
<template>
  <div
    v-if="$asf.debugging.showDebugBar"
    style="z-index: 100"
  >
    <v-switch
      v-model="drawer"
      class="drawerSwitch"
    />
    <v-navigation-drawer
      v-model="drawer"
      right
      fixed
      clipped
      style="z-index: 100"
      width="480"
    >
      <v-switch
        v-model="drawer"
        class="drawerSwitch"
      />
      <h3 class="text--primary ml-4 mt-2">
        DEBUG BAR
      </h3>
      <h4 class="text--primary ml-4">
        Form Config
      </h4>
      <div class="d-flex flex-row-reverse">
        <v-text-field
          v-model="expandDepthCurrentPath"
          class="mt-4 mr-4 expandDepthInput"
          label="Set expand depth"
          required
          type="number"
          outlined
        />
      </div>
      <json-viewer
        :key="updateKeyCurrentPath"
        :expand-depth="parseInt(expandDepthCurrentPath)"
        copyable
        :value="dataCurrentPath"
      />

      <h4 class="ml-4 my-2">
        Settings Variables
      </h4>
      <div class="d-flex flex-row-reverse">
        <v-text-field
          v-model="expandDepthSettingsVariables"
          class="mt-4 mr-4 expandDepthInput"
          label="Set expand depth"
          required
          type="number"
          outlined
        />
      </div>
      <json-viewer
        :key="updateKeySettingsVariables"
        :expand-depth="parseInt(expandDepthSettingsVariables)"
        copyable
        :value="settingsVariables"
      />

      <h4 class="ml-4 my-2">
        Shared Variables
      </h4>
      <div class="d-flex flex-row-reverse">
        <v-text-field
            v-model="expandDepthSharedVariables"
            class="mt-4 mr-4 expandDepthInput"
            label="Set expand depth"
            required
            type="number"
            outlined
        />
      </div>
      <json-viewer
          :key="updateKeySharedVariables"
          :expand-depth="parseInt(expandDepthSharedVariables)"
          copyable
          :value="sharedVariables"
      />

      <h4 class="ml-4 my-2">
        Last mutation
      </h4>
      <div class="d-flex flex-row-reverse">
        <v-text-field
          v-model="expandDepthLastMessage"
          class="mt-4 mr-4 expandDepthInput"
          label="Set expand depth"
          required
          type="number"
          outlined
        />
      </div>
      <json-viewer
        :key="updateKeyLastMessage"
        :expand-depth="parseInt(expandDepthLastMessage)"
        copyable
        :value="getLastMessage"
      />
      <div
        name="spacer"
        style="height: 100px"
      />
    </v-navigation-drawer>
  </div>
</template>

<script>
const initializeValue = function(value, initValue = '') {
  return value !== undefined ? value : initValue
}

export default {
  data: () => ({
    text: 'Lorem ipsum dolor sit amet',
    vertical: true,
    drawer: true,
    group: null,
    updateKeyCurrentPath: 1,
    updateKeyCustomData: 10000,
    updateKeyProjectSettings: 100000,
    updateKeySettingsVariables: 10000000,
    updateKeySharedVariables: 1000000000,
    updateKeyLastMessage: 100000000000,
    expandDepthCurrentPath: 3,
    expandDepthCustomData: 4,
    expandDepthProjectSettings: 1,
    expandDepthSettingsVariables: 1,
    expandDepthSharedVariables: 1,
    expandDepthLastMessage: 1,
  }),
  computed: {
    showDebugBar() {
      return this.$store.state.AsfForms.settingsVariables.showDebugBar
    },
    getLastMessage() {
      return initializeValue(
        // eslint-disable-next-line
        this.$store.getters[`AsfMessages/getLastMutationMessage`]
      )
    },
    currentPath() {
      return this.$store.state.AsfForms.currentPath
    },
    dataCurrentPath() {
      return initializeValue(
        this.$store.state.AsfForms.forms[this.currentPath],
      )
    },
    settingsVariables() {
      return initializeValue(this.$store.state.AsfForms.settingsVariables, {})
    },
    sharedVariables() {
      return initializeValue(this.$store.state.AsfForms.sharedVariables, {})
    },
    // customData() {
    //   return this.$store.state.AsfForms.forms.panelMenuTest.panels[0].formItems[0];
    // }
  },
  watch: {
    showDebugBar(value) {
      if (value) {
        this.drawer = true
      }
    },
    currentPath() {
      this.updateKeyCurrentPath += 1
      this.updateKeyCustomData += 1
      this.updateKeyProjectSettings += 1
      this.updateKeyLastMessage += 1
      this.updateKeySettingsVariables += 1
      this.updateKeySharedVariables += 1
    },
    getLastMessage() {
      // this.drawer = true;
    },
    expandDepthCurrentPath() {
      this.updateKeyCurrentPath += 1
    },
    expandDepthCustomData() {
      this.updateKeyCustomData += 1
    },
    expandDepthProjectSettings() {
      this.updateKeyProjectSettings += 1
    },
    expandDepthSettingsVariables() {
      this.updateKeySettingsVariables += 1
    },
    expandDepthSharedVariables() {
      this.updateKeySharedVariables += 1
    },
    expandDepthLastMessage() {
      this.updateKeyLastMessage += 1
    },
    customData() {
      this.updateKeyCustomData += 1
    },
  },
  mounted() {},
  methods: {},
}
</script>

<style lang="scss">
.drawerSwitch {
  position: fixed;
  right: 10px;
  top: 2px;
  z-index: 1000;
}

.jv-container .jv-code {
  overflow: hidden;
  padding: 0 20px 10px !important;
}

.expandDepthInput {
  max-width: 200px !important;
  transform: scale(0.7)
}

</style>
