<template>
  <div>
    <asf-debug
      v-if="showDebugger"
      :form-item="formItem"
      :form-name="formName"
      :panel-id="panelId"
    />

    <component
      :is="componentName"
      :id="formItemId"
      :panel-visible="panelVisible"
      :form-item="formItem"
      :form-key="formKey"
      :form-name="formName"
      :read-only="readOnly"
      :by-pass-required="byPassRequired"
      :class="nestedProperty(formItem, 'layout', 'class')"
      @change="updateFormItemData"
      @setTempData="setFormItemTempData"
      @setMetaData="setFormItemMetaData"
      @setValueObject="setValueObject"
      @setValueObjects="setValueObjects"
    />

    <div
      v-if="$asf.debugging.showPanelAndFormIds"
      class="ml-2 text-caption"
      style="margin-top: -18px"
    >
      formItem.id = {{ formItem.id }}
    </div>
  </div>
</template>

<script>
import { nestedProperty } from '../../helpers/nested-property'

export default {
  props: {
    panelId: {
      type: Number,
      required: true,
    },
    panelVisible: {
      type: Boolean,
      required: true,
    },
    formItem: {
      type: Object,
      required: true,
    },
    formName: {
      type: String,
      required: true,
    },
    formConfig: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localValue: this.formItem.value,
      showPassword: false,
      localItems: null,
    }
  },
  computed: {
    /**
     * Merge of the custom and core form items, this is being bootstrapped in the main.js
     */
    formComponentsLibrary() {
      const library = [
        ...this.$store.state.AsfForms.registerCoreFormItems,
        ...this.$store.state.AsfForms.registerCustomFormItems,
      ]
      return library.filter(
        (formComponent) => this.formItem.type === formComponent.formItemType,
      )
    },
    componentName() {
      return nestedProperty(this.formComponentsLibrary[0], 'moduleName')
    },
    settingsVariables() {
      return this.$store.state.AsfForms.settingsVariables
    },
    renamables() {
      return this.$store.getters['AsfForms/renamables']
    },
    formItemId() {
      // return `${this.panelId}_${this.formItem.id}`;
      return this.formKey
    },
    formKey() {
      return `${this.formName}_${this.panelId}_${this.formItem.id}`
    },
    showDebugger() {
      return (
        nestedProperty(this.formItem, 'debug') ||
        (nestedProperty(this.settingsVariables, 'showFormItemData') &&
          this.formName !== 'debugSettings')
      )
    },
    byPassRequired() {
      return this.formConfig.byPassRequired || false
    },
  },
  watch: {
    localValue: function (value) {
      this.updateFormItemData(value)
    },
    byPassRequired: function (value) {
      // eslint-disable-next-line
      console.log('byPassRequired in formItemBuilder', value)
    },
  },
  beforeMount() {
    this.setFormVariable(this.formItem.value)
  },
  methods: {
    nestedProperty,
    processReplacements(value) {
      return this.$store.getters['AsfForms/processRenamables'](value)
    },
    updateFormItemData(value, metaData = undefined) {
      const contextUpdatedItem = {
        panelId: this.panelId,
        itemId: this.formItem.id,
        value: value,
      }

      if (metaData) {
        contextUpdatedItem.metaData = metaData
      }
      this.setFormVariable(value)
      this.setSettingsVariable(value)
      this.setSharedVariable(value)
      this.$emit('itemUpdated', contextUpdatedItem)
    },
    setFormItemTempData(value) {
      const contextTempData = {
        panelId: this.panelId,
        itemId: this.formItem.id,
        tempData: value,
      }
      this.$emit('tempDataUpdated', contextTempData)
    },
    setFormItemMetaData(value) {
      const contextMetaDataObject = {
        panelId: this.panelId,
        itemId: this.formItem.id,
        metaData: value,
      }
      this.$emit('metaDataUpdated', contextMetaDataObject)
    },
    setValueObject(value) {
      const contextValueObject = {
        panelId: this.panelId,
        itemId: this.formItem.id,
        valueObject: value,
      }
      this.$emit('valueObjectUpdated', contextValueObject)
    },
    setValueObjects(value) {
      const contextValueObjects = {
        panelId: this.panelId,
        itemId: this.formItem.id,
        valueObjects: value,
      }
      this.$emit('valueObjectsUpdated', contextValueObjects)
    },
    setLocalItems(items) {
      this.localItems = items
    },
    setFormVariable(value) {
      // if (this.formItem?.options?.formVariableKey) {
      if (nestedProperty(this.formItem, 'options', 'formVariableKey')) {
        const data = {
          key: this.formItem.options.formVariableKey,
          value: value,
          formName: this.formName,
        }
        this.$store.dispatch('AsfForms/setFormVariable', data)
      }
    },
    setSettingsVariable(value) {
      if (nestedProperty(this.formItem, 'options', 'settingsKey')) {
        const data = {
          key: this.formItem.options.settingsKey,
          value: value,
        }
        this.$store.dispatch('AsfForms/setSettingsVariable', data)
      }
    },
    setSharedVariable(value) {
      if (nestedProperty(this.formItem, 'share', 'to')) {
        const data = {
          key: this.formItem.share.to,
          value: value,
        }
        this.$store.dispatch('AsfForms/setSharedVariable', data)
      }
    },
  },
}
</script>
