export const initialize = function() {
  this.initializeMetaData()
  this.initializeLocalSelectables()
  this.initializeLocalItems()
  this.$forceUpdate()
}

////////////////////////////////////////////////////////////
// Metadata for textfilters of the matrix editor          //
////////////////////////////////////////////////////////////

export const initializeMetaData = function() {
  if (this.metaData) {
    this.useTextFilter = this.metaData.useTextFilter
    this.typeOfTextFilter = this.metaData.typeOfTextFilter
    this.textFilter = this.metaData.textFilter
  }
}

////////////////////////////////////////////////////////////
// The Columns (selectables) of the matrix editor         //
////////////////////////////////////////////////////////////

export const initializeLocalSelectables = function() {
  this.localSelectables = [...this.selectables]
  if (!this.singleSelect && this.useCustomMatrixHeaders) {
    this.addCustomSelectables()
  }
  this.adaptAllSelectablesToCustomNamingConvention()
  this.initializeHeaders()
}

export const addCustomSelectables = function() {
  const customMatrixHeaders = this.$store.getters[
    'AsfForms/getCustomMatrixHeaders'
  ](this.name)
  customMatrixHeaders.forEach(customHeader => {
    this.localSelectables.push(customHeader)
  })
}

export const adaptAllSelectablesToCustomNamingConvention = function() {
  if (this.namingConventions) {
    this.localSelectables.map(selectable => {
      const lookUpName = `customNameFor${selectable.columnName}`

      if (
        Object.prototype.hasOwnProperty.call(this.settingsVariables, lookUpName)
      ) {
        selectable.namingConvention = this.settingsVariables[lookUpName]
        selectable.headerText = this.settingsVariables[lookUpName]
      }

      return selectable
    })
  }
}

export const initializeHeaders = function() {
  this.headers = [this.initialHeader]
  this.localSelectables.forEach(s => {
    const width = s.width ? s.width : '12%'
    this.headers.push({
      text: s.headerText,
      value: s.columnName,
      width: width,
    })
  })
  if (this.singleSelect) {
    this.headers.reverse()
  }
}

////////////////////////////////////////////////////////////
// The rows (records / items) of the matrix editor        //
////////////////////////////////////////////////////////////

export const initializeLocalItems = function() {
  if (this.items !== undefined && this.items.length > 0) {
    this.localItems = [...this.items]
  } else {
    if (this.tempData === undefined) {
      this.localItems = this.$store.getters[this.fetchItems.getter]
      this.$emit('setTempData', JSON.stringify(this.localItems))
    } else {
      this.restoreLocalItemsFromTempItems()
    }
  }
}

// After reinitialization of the Matrix
// We would like to restore previously
// made selections stored in this.tempData
// console.log(this.tempData);

export const restoreLocalItemsFromTempItems = function() {
  this.selectNone()
  const tempItems = JSON.parse(this.tempData)
  this.localItems = this.$store.getters[this.fetchItems.getter]
  if (tempItems.length > 0) {
    // If item with the same id is in the tempItems array
    // replace it with the one in the tempItems arrays

    let transform = this.localItems.map(item => {
      const found = tempItems.find(x => x.id === item.id)
      if (found !== undefined) {
        return found
      }
      return item
    })

    // Create a flat array of allowed attributes for the items
    // this is to remove unwanted columns as they could have been
    // removed.

    let selectables = this.localSelectables.map(selectable => {
      return selectable.columnName
    })
    selectables.push('id')
    selectables.push('name')

    // Remove unwanted attributes from new array

    transform = transform.map(item => {
      Object.keys(item).forEach(key => {
        if (!selectables.includes(key)) {
          delete item[key]
        }
      })
      return item
    })
    this.$emit('setTempData', JSON.stringify(transform))
    this.localItems = transform
  }
}
