const deepMerge = require('deepmerge')
const {
  defaultValidationRulesForFormItems,
} = require('./validation/default-validation-rules-for-form-items')
const { USAGE } = require('../valueholders/usage')

class MergeModelWithFormConfig {
  constructor({ formConfig, model, useIn = USAGE.FRONTEND }) {
    this.formConfig = JSON.parse(JSON.stringify(formConfig))
    this.model = model
    this.useIn = useIn
    this.merge()
    return this.formConfig
  }

  merge() {
    this.formConfig.panels.map(panel => {
      panel.formItems.map(formItem => {
        return this.mergeMappedValidation(formItem)
      })
      return panel
    })
  }

  mergeMappedValidation(formItem) {
    const formItemTypesToExclude = ['helpText', 'helpTextHtml']

    if (formItemTypesToExclude.includes(formItem.type)) {
      return formItem
    }

    if (!Object.hasOwnProperty.call(formItem, 'map')) {
      // eslint-disable-next-line
      console.error('This formItem has no map specified', formItem)
      return formItem
    }

    const modelEntry = this.model[formItem.map]

    if (modelEntry === undefined) {
      // eslint-disable-next-line
      console.error('There\'s no model available', formItem.map)
      return formItem
    }

    if (!Object.hasOwnProperty.call(modelEntry, 'dataType')) {
      // eslint-disable-next-line
      console.error('The model has no dataType', formItem.map)
      return formItem
    }

    formItem.dataType = modelEntry.dataType
    formItem = this.mergeMappedGenericValidation(formItem)
    formItem = this.mergeMappedValidationFrontEnd(formItem)
    formItem = this.mergeMappedValidationBackEnd(formItem)

    // After all validation-rules have been merged we take a look if
    // minimum validation rules are set and if not we set some defaults
    // here implicitly.

    formItem = this.setDefaultValidationRules(formItem)

    return formItem
  }

  mergeMappedGenericValidation(formItem) {
    if (Object.hasOwnProperty.call(this.model[formItem.map], 'validation')) {
      formItem.validation = this.model[formItem.map].validation
    } else {
      formItem.validation = {}
    }
    return formItem
  }

  mergeMappedValidationFrontEnd(formItem) {
    if (
      this.useIn === USAGE.FRONTEND &&
      Object.hasOwnProperty.call(
        this.model[formItem.map],
        'validation_front_end',
      )
    ) {
      formItem.validation = deepMerge(
        formItem.validation,
        this.model[formItem.map].validation_front_end,
      )
    }
    return formItem
  }

  mergeMappedValidationBackEnd(formItem) {
    if (
      this.useIn === USAGE.BACKEND &&
      Object.hasOwnProperty.call(
        this.model[formItem.map],
        'validation_back_end',
      )
    ) {
      formItem.validation = deepMerge(
        formItem.validation,
        this.model[formItem.map].validation_back_end,
      )
    }
    return formItem
  }

  setDefaultValidationRules(formItem) {
    defaultValidationRulesForFormItems.forEach(validator => {
      formItem = validator({ formItem, model: this.model })
    })

    return formItem
  }
}

module.exports = { MergeModelWithFormConfig }
