export class MapFormToRecordData {
  constructor(panels) {
    this.panels = panels
    this.dataRecord = {}
    this.parsePanels()
    // return this.dataRecord;
  }

  parsePanels() {
    // gather data from all formItems of allPanels that where visible
    // this way the relevant data only will be mapped.

    this.panels.forEach(panel => {
      panel.formItems.forEach(formItem => {
        const panelIsVisible = panel.show.value === true
        const formItemIsVisible =
          formItem.show.value === true && panelIsVisible
        const formItemIsNotVisible = !formItemIsVisible
        const formItemHasMap = Object.keys(formItem).includes('map')
        const formItemHasValueObject = Object.keys(formItem).includes(
          'valueObject',
        )
        const formItemHasValueObjects = Object.keys(formItem).includes(
          'valueObjects',
        )

        if (formItemHasMap) {
          // map visible items

          if (formItemIsVisible) {
            if (formItemHasValueObject) {
              this.dataRecord[`${formItem.map}`] = formItem.valueObject
            } else if (formItemHasValueObjects) {
              this.dataRecord[`${formItem.map}`] = formItem.valueObjects
            } else if (!formItemHasValueObject && !formItemHasValueObjects) {
              this.dataRecord[`${formItem.map}`] = formItem.value
            }
          }

          // if a formItem is not visible it should be mapped with 'undefined'

          if (formItemIsNotVisible) {
            this.dataRecord[`${formItem.map}`] = undefined
          }
        }
      })
    })
  }
}
