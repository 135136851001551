import { FormItemValidation } from '../logic/form-item-validation'

// Replacement for javascript optional chaining
// example:
// this.formItem?.options?.itemValue
// can be replaced by:
// nestedProperty(this.formItem, "options", "itemValue");

import { nestedProperty } from '../../../helpers/nested-property'

// The mixinFormItemGeneric is a mixin that interfaces
// (custom) formItems with the logic of Accow Stateful Forms

export const mixinFormItemGeneric = {
  props: {

    // access to the complete formItem object from the formConfig

    formItem: Object,

    // access to the parent formConfig the formItem is part of

    formConfig: Object,

    // boolean to see if the container panel the formItem is a child of
    // is visible. If the panel is not visible logic in the formItem
    // should behave different (e.g. byPass validation rules)

    panelVisible: Boolean,

    // boolean to see if the required rule for needs to be bypassed as a result
    // of a higher level bypass decision

    byPassRequired: Boolean,

    // boolean to toggle an item to be read-only

    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {

      // IMPORTANT: you can overwrite this attribute in your components' data()
      // to add component specific rules to a component

      customRules: [],

      // the rules attribute is set in the beforeMount() lifecycle, triggering
      // the setRules method that handles providing the component with the
      // necessary rules

      rules: [],
    }
  },
  watch: {

    // If the panel's (formItem's parent) visibility changes
    // we have to update the formItem's rules

    panelVisible: {
      handler: function() {
        this.setRules()
      },
      deep: true,
    },

    // If byPassRequired changes we have to re-evaluate the rules for the formItem
    // to update the rules

    byPassRequired() {
      this.setRules()
    },
  },
  computed: {

    // two way binding of the formItem's value

    localValue: {
      get() {
        return this.formItem.value
      },

      // the emission of 'change' triggers upward delegation
      // of handling the value change (e.g. mutating the ASF store)

      set(value) {
        this.$emit('change', value)
        this.handleValueObjectChange(value)
      },
    },

    // this computed value assesses the formItem's visibility
    // and is used in the component to update its behaviour
    // when it changes

    formItemVisible() {
      return (
        this.panelVisible &&
        nestedProperty(this.formItem, 'show', 'value') === true
      )
    },

    // a formItem could make use of 'items', for example as
    // selection options. You could either set the value
    // formItem.items direct OR you could use a VUEX store getter

    localItems() {
      let localItems = []
      if (nestedProperty(this.formItem, 'items', 'length') > 0) {
        localItems = [...this.formItem.items]
      } else if (
        nestedProperty(this.formItem, 'fetchItems', 'getter') !== undefined
      ) {
        localItems = this.$store.getters[this.formItem.fetchItems.getter]
      }
      return localItems
    },


    //////////////////////
    // DEPRECATED START //
    //////////////////////

    // each formItem can store its value as a settingsKey
    // e.g. formItem.options.settingsKey = 'exampleKey',
    // this means that the value of the formItem could be fetched
    // with the store getter like so:
    // this.$store.getters['AsfForms/listenToSettingsKey']('exampleKey')
    // To listen to a settingsKey that has been set by another
    // formItem (even in another form), you could set:
    // formItem.listener.settingsKey: 'exampleKey'

    settingsKeyFromGetter() {
      const settingsKey = nestedProperty(
        this.formItem,
        'listener',
        'settingsKey',
      )
      return settingsKey !== undefined
        ? this.$store.getters['AsfForms/listenToSettingsKey'](settingsKey)
        : null
    },

    ////////////////////
    // DEPRECATED END //
    ////////////////////

    // in the model (or directly in the formItem config) you could set
    // the required rule for a formItem. This rule will invalidate the
    // parent form if the value is omitted
    // We could toggle the formConfig.byPassRequired to true,
    // this way the form will do its validation but ignore the required rule

    required() {
      return (
        (nestedProperty(this.formItem, 'validation', 'required') &&
          this.formItem.byPassRequired === false) ||
        false
      )
    },

    // returns an optional length indicator

    counter() {
      return nestedProperty(this.formItem, 'options', 'length') || null
    },

    // string to return a title

    title() {
      return this.processReplacements(this.formItem.title)
    },

    // boolean used if a formItem can be cleared

    clearable() {
      return nestedProperty(this.formItem, 'options', 'clearable') || false
    },

    // boolean used to allow a selection of multiple values

    multiple() {
      return nestedProperty(this.formItem, 'options', 'multiple') || null
    },

    // string that some formItems (can) use to give the user a hint

    hint() {
      return nestedProperty(this.formItem, 'options', 'hint') || null
    },

    // boolean used for some formItems to display as a 'block'

    block() {
      return nestedProperty(this.formItem, 'options', 'block') || false
    },

    // if a formItem has a multiSelect that uses an array of objects as
    // items e.g. set in `formItem.items` or via a getter:
    // 'formItem.fetchItems.getter' it needs to know which attribute
    // of the items object to use as the displayed text 'itemText'
    // and which as its value 'itemValue'.

    itemText() {
      return nestedProperty(this.formItem, 'options', 'itemText') || 'title'
    },

    // ^^^ see explanation 'itemText' above

    itemValue() {
      return nestedProperty(this.formItem, 'options', 'itemValue') || 'id'
    },
  },
  methods: {

    // A formItem can share its value with other formItems
    // (even in other forms)
    // Make a formItems value available:
    //  formItem: {
    //    ...
    //    share: {
    //        to: "exampleKey"
    //    },
    //  }
    // And bind (get) this sharedVariable:
    //
    //  anotherFormItem: {
    //    ...
    //    share: {
    //      from: {
    //        localKeyName: "exampleKey"
    //      },
    //    },
    //  }

    getSharedVariable(localKeyName) {
      const settingsKey = nestedProperty(
        this.formItem,
        'share',
        'from',
        localKeyName
      )
      return settingsKey !== undefined
        ? this.$store.getters['AsfForms/getSharedVariable'](settingsKey)
        : null
    },

    processReplacements(value) {
      return this.$store.getters['AsfForms/processRenamables'](value)
    },

    // if the value is an object OR an array of objects we use the method
    // below to handle its change ultimately to delegate the change (emit) upwards.

    handleValueObjectChange(value) {
      // Exclude formItemTypes from processing

      const arrayOfFormItemTypesToExclude = [
        'matrixSelect',
        'multiColumnSelect',
      ]

      const formItemTypeThatShouldBeExcluded = formItemType => {
        return arrayOfFormItemTypesToExclude.includes(formItemType)
      }

      if (
        value === null ||
        value === undefined ||
        formItemTypeThatShouldBeExcluded(this.formItem.type)
      ) {
        return
      }

      const valueIsAnArray = Array.isArray(value)

      if (
        this.localItems.length > 0 &&
        typeof this.localItems[0] === 'object'
      ) {
        if (!valueIsAnArray) {
          this.handleValueObjectChangeSingleSelect(value)
        }

        if (valueIsAnArray) {
          this.handleValueObjectChangeMultiSelect(value)
        }
      }
    },

    // ^^^ sub function of handleValueObjectChange
    // handle the case that the value contains a single object

    handleValueObjectChangeSingleSelect(value) {
      // For all formItems that use localItems we would like to store
      // the value ALSO as an object in the formItem attribute 'valueObject'.

      // Mostly an array of objects is selected by the value of an object's 'id'
      // but since you can also adapt the value to another key we parse it in objectValueKey

      const objectValueKey =
        Object.hasOwnProperty.call(this.formItem, 'options') &&
        Object.hasOwnProperty.call(this.formItem.options, 'itemValue')
          ? this.formItem.options.itemValue
          : 'id'

      const valueObjectItem = this.localItems.find(
        item => item[objectValueKey] === value,
      )
      const valueObject = JSON.parse(JSON.stringify(valueObjectItem))
      delete valueObject.show
      this.$emit('setValueObject', valueObject)
    },

    // ^^^ sub function of handleValueObjectChange
    // handle the case that the value contains an array of objects

    handleValueObjectChangeMultiSelect(valueArray) {
      // For all formItems that use localItems AND have the ability to multiselect
      // we would like to store the value ALSO as an array of objects in the formItem
      // attribute 'valueObjects'.

      // Mostly an array of objects is selected by the value of an object's 'id'
      // but since you can also adapt the value to another key we parse it in objectValueKey

      const objectValueKey =
        Object.hasOwnProperty.call(this.formItem, 'options') &&
        Object.hasOwnProperty.call(this.formItem.options, 'itemValue')
          ? this.formItem.options.itemValue
          : 'id'

      const valueObjects = valueArray.map(valueItem => {
        const foundItem = this.localItems.find(
          localItem => localItem[objectValueKey] === valueItem,
        )
        delete foundItem.show
        return foundItem
      })
      this.$emit('setValueObjects', valueObjects)
    },

    // the setRules method sets the validation rules for the formItem
    // and handles cases that it should not be set

    setRules() {

      // We add byPassRequired as an attribute for handling in the FormItemValidation class

      this.formItem.byPassRequired = this.byPassRequired

      // We temporarily omit validation-rules if the form-item or its panel is not visible
      this.rules = this.formItemVisible
        ? [...this.customRules, ...new FormItemValidation(this.formItem)]
        : []
    },
  },
  beforeMount() {
    this.setRules()

    // If the selectable values (this.localItems) are
    // objects we would like to store the selected value(s)
    // as object(s) as well

    this.handleValueObjectChange(this.localValue)
  },
}
