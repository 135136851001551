const asfMessages = {
  namespaced: true,
  state: {
    mutationMessages: ['test message'],
    showMutationSnackbar: false,
  },
  getters: {
    getLastMutationMessage(state) {
      return state.mutationMessages[state.mutationMessages.length - 1]
    },
  },
  mutations: {
    setShowMutationSnackbar(state, value) {
      state.showMutationSnackbar = value
    },
    addMutationMessage(state, value) {
      state.mutationMessages.push(value)
    },
  },
  actions: {
    setShowMutationSnackbar({ commit }, value) {
      commit('setShowMutationSnackbar', value)
    },
    showMessageInSnackbar({ commit, dispatch }, value = 'an example message') {
      commit('addMutationMessage', value)
      dispatch('setShowMutationSnackbar', true)
    },
  },
}

export { asfMessages }
